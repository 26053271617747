import {CMSImage, CMSLink, CMSWysiwyg} from 'cms-types'

import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import React from 'react'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Footer.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export const Footer: React.FC = () => {
	const {general} = useApp()
	const {items_left, items_right, greenhosting} = general.footer

	return (
		<div className={styles.footer()}>
			<Container>
				<div className={styles.footer.row()}>
					<div className={styles.footer.row.col()}>
						{items_left?.length > 0 &&
							items_left.map((item, i) => (
								<FooterItem {...item} key={i} row={true} />
							))}
						<FooterGreenhosting greenhosting={greenhosting} />
					</div>
					<div className={styles.footer.row.col()}>
						{items_right?.length > 0 &&
							items_right.map((item, i) => <FooterItem {...item} key={i} />)}
					</div>
				</div>
			</Container>
		</div>
	)
}

const FooterGreenhosting: React.FC<{greenhosting: string}> = ({
	greenhosting
}) => {
	if (!greenhosting) return null
	return (
		<div className={styles.greenhosting()}>
			<Image
				type="image"
				src="/greenhosting.png"
				width={26}
				height={21}
				focus={{x: 0.5, y: 0.5}}
				alt="Green hosting"
				title="Green hosting"
				layout="fixed"
				sizes="52px"
				className={styles.greenhosting.image()}
			/>
			<p className={styles.greenhosting.text()}>{greenhosting}</p>
		</div>
	)
}

const FooterItem: React.FC<{
	image: CMSImage
	link?: CMSLink
	text: CMSWysiwyg
	row?: boolean
}> = ({image, link, text, row}) => {
	const LinkTag = link?.url ? Link : 'div'
	const linkProps = link?.url ? {to: link.url} : {}

	return (
		<div className={styles.item.mod({row})()}>
			{image?.src && (
				<LinkTag
					{...linkProps}
					title={link?.description || link?.title}
					className={styles.item.image()}
				>
					<Image {...image} layout="responsive" sizes="260px" priority />
				</LinkTag>
			)}
			<Wysiwyg className={styles.item.text()}>{text}</Wysiwyg>
		</div>
	)
}
