import {
	AutogidsAandrijvingBron,
	AutogidsAandrijvingCategory,
	AutogidsAandrijvingSubcategory,
	AutogidsVorm
} from 'autogids/autogids.state'
import {GlasType, RaamkaderMateriaal} from 'calculators/vensters/vensters.state'
import {
	IsolatiegraadType,
	VerwarmingsType,
	WagenBrandstofType,
	WagenGroeneStroomType,
	WoningType
} from 'calculators/profile.channel'
import {MuurIsolatiePositie, MuurType} from 'calculators/muren/muren.state'

import {CalculationUnit} from 'layout/calculators/Calculation'
import {DroogkastCapaciteit} from 'calculators/droogkast/droogkast.state'
import {EnergieLabel} from 'calculators/labels'
import {HellendDakType} from 'calculators/dak/dak.state'
import {OvenDimensie} from 'calculators/oven/oven.channel'
import {OvenEnergiebron} from 'calculators/oven/oven.state'
import {ProfileAttribute} from 'calculators/Profile'
import {Rijstijl} from 'calculators/wagen/wagen_rijden/wagenrijden.state'
import {VaatwasserCapaciteit} from 'calculators/vaatwasser/vaatwasser.state'
import {VerwarmingsInstallatieType} from 'calculators/verwarming/verwarming.state'
import {WasmachineCapaciteit} from 'calculators/wasmachine/wasmachine.state'

export const nl = {
	projectname: 'EnergyWatchers',
	federalheader: {
		text: 'Andere informatie en diensten van de overheid:',
		link: {
			title: 'www.belgium.be',
			url: 'https://www.belgium.be/nl'
		}
	},
	menu: {
		account: {
			url: '/nl/account',
			title: 'Mijn EnergyWatchers'
		},
		search: {
			url: '/nl/zoeken',
			title: 'Zoeken'
		}
	},
	account: {
		header_toast:
			'Vergeet niet <u>in te loggen of een account aan te maken</u> om je voorkeuren op te slaan.',
		register: {
			title: 'Registreren',
			name: 'Naam',
			email: 'E-mailadres',
			password: 'Wachtwoord',
			repeat_password: 'Herhaal wachtwoord',
			privacy_agreement:
				'Ik ga akkoord met de <a href="/nl/pagina" target="_blank">privacyvoorwaarden</a>.',
			newsletter: 'Ik wil nieuws ontvangen van EnergyWatchers.',
			login: 'Log in met een bestaand account',
			submit: 'Registreer',
			mail_sent: {
				message:
					'Je account is aangemaakt maar moet eerst nog geactiveerd worden. We hebben je een e-mail gestuurd met een activatielink.<br />Klik op deze link om je account te activeren.',
				no_mail: 'Geen e-mail ontvangen?',
				button: 'Opnieuw verzenden'
			}
		},
		login: {
			title: 'Inloggen',
			email: 'E-mailadres',
			password: 'Wachtwoord',
			forgot_password: 'Wachtwoord vergeten',
			register: 'Maak een nieuw account aan',
			submit: 'Login'
		},
		forgot_password: {
			title: 'Wachtwoord resetten',
			email: 'E-mailadres',
			cancel: 'Annuleren',
			submit: 'Verzend',
			mail_sent: {
				message:
					'Je wachtwoord is gereset maar moet eerst nog geactiveerd worden. We hebben je een e-mail gestuurd met een activatielink.<br />Klik op deze link om je account te herstellen.',
				no_mail: 'Geen e-mail ontvangen?',
				button: 'Opnieuw verzenden'
			}
		},
		logout: 'Uitloggen',
		update: {
			title: 'Gegevens',
			name: 'Naam',
			email: 'E-mailadres',
			password: 'Nieuw wachtwoord',
			repeat_password: 'Herhaal nieuw wachtwoord',
			submit: 'Opslaan',
			delete: 'Account verwijderen',
			confirm: 'Ben je zeker dat je je account wilt verwijderen?',
			subscribe: 'Schrijf me in'
		},
		toasts: {
			//General toasts
			pending: 'Je gegevens worden verwerkt',
			error: 'Er ging iets mis bij het opslaan',
			success: 'Je gegevens zijn bijgewerkt',
			warning: 'Lokale opslag is niet beschikbaar',

			//Specific toasts
			incorrect_login: 'De combinatie email/wachtwoord is niet correct',
			pending_login: 'Bezig met inloggen',
			account_deleted: 'Je account is verwijderd',
			account_created: 'Je account is aangemaakt',
			activate: 'Je account is gecreëerd. Ontdek hier je persoonlijke ruimte',
			passwords_dont_match: 'De paswoorden kloppen niet',
			logged_in: 'Succesvol ingelogd',
			logged_out: 'U bent niet langer ingelogd',
			password_reset: 'Er werd een mail verzonden om je wachtwoord te resetten',
			success_mail: 'E-mail werd verzonden',
			success_subscribe: 'Succesvol ingeschreven'
		}
	},
	tips: {
		share: 'Deel',
		favorite: {
			save: 'Bewaar',
			delete: 'Bewaard'
		},
		status: {
			favorite: 'Favoriet',
			todo: 'Te doen',
			done: 'Al gedaan'
		},
		purchase: 'Aankooptip',
		behaviour: 'Gedragstip',
		environment: 'Milieutip',
		premiums: 'Premies',
		info: 'Info',
		impact: 'Impact',
		investment: 'Investering',
		loadmore: 'Meer tips laden',
		items: 'items',
		sort: 'Sorteren:',
		sort_randomly: 'Willekeurig sorteren',
		sort_impact: 'Sorteren op impact',
		sort_investment: 'Sorteren op investering',
		find_tips: 'Vind tips over...',
		themes: 'Thema`s',
		filters: {
			reset: 'Alle filters wissen',
			title: 'Filters',
			status: {
				title: 'Status',
				favorite: 'Favoriet',
				done: 'Al gedaan',
				open: 'Nog te doen'
			},
			type: {
				title: 'Type',
				purchase: 'Aankooptip',
				behaviour: 'Gedragstip',
				environment: 'Milieutip',
				premiums: 'Premies',
				info: 'Info'
			},
			impact: {
				title: 'Impact'
			},
			investment: {
				title: 'Investering',
				'0': '0-10 €',
				'1': '10-100 €',
				'2': '100-1.000 €',
				'3': '1.000-10.000 €',
				'4': '> 10.000 €'
			},
			subcategory: {
				title: 'Onderwerp'
			}
		},
		empty:
			'Er zijn geen resultaten gevonden die aan de opgegeven criteria voldoen.',
		toasts: {
			error: 'Er ging iets mis bij het opslaan.',
			favorite_add: 'Tip toegevoegd aan favorieten.',
			favorite_remove: 'Tip verwijderd uit favorieten.',
			tip_add: 'Tip uitgevoerd.',
			tip_remove: 'Tip nog uit te voeren.'
		}
	},
	inspiration: {
		linklabel: 'Lees het artikel',
		loadmore: 'Meer inspiratie laden',
		items: 'items',
		sort: 'Sorteren:'
	},
	related: 'Ook interessant voor jou',
	share: {
		label: 'Delen',
		click: 'Klik om te delen op'
	},
	social: {
		facebook: 'Facebook',
		linkedin: 'LinkedIn',
		reddit: 'Reddit',
		telegram: 'Telegram',
		twitter: 'Twitter',
		whatsapp: 'WhatsApp'
	},
	contact: {
		email: {
			label: 'E-mailadres',
			placeholder: 'Je e-mailadres'
		},
		subject: {
			label: 'Onderwerp',
			placeholder: 'Het onderwerp van je bericht'
		},
		message: {
			label: 'Bericht',
			placeholder: 'Je bericht'
		},
		submit: 'Verzenden',
		toasts: {
			error: 'Er ging iets mis bij het verzenden',
			pending: 'Je bericht wordt verzonden',
			success: 'Je bericht is verzonden'
		}
	},
	search: {
		placeholder: 'Geef uw zoekterm op...',
		title: 'Zoekresultaten',
		empty: 'Geen resultaten gevonden voor de huidige zoekopdracht.'
	},
	profile: {
		title: 'Je persoonlijk profiel',
		link: 'Mijn volledig profiel bekijken',
		reset: 'Terugzetten naar gemiddelde waarde',
		close: 'Sluiten',
		groene_stroom_checkbox: 'groene energie',
		categories: {
			gezin: 'Gezin',
			woning: 'Woning',
			elektriciteit: 'Elektriciteit',
			verwarming: 'Verwarming',
			water: 'Water',
			aardgas: 'Aardgas (voor koken)',
			wagen: 'Wagen'
		},
		attributes: {
			gezinsleden: 'Aantal gezinsleden',
			woningtype: 'Type woning',
			isolatiegraad: 'Isolatiegraad',
			groene_stroom: 'Groene stroom',
			stroomprijs: 'Kostprijs',
			jaarverbruik: 'Jaarverbruik',
			kamertemperatuur: 'Gemiddelde kamertemperatuur',
			verwarmingsinstallatie: 'Huidige installatie',
			verwarmingstype: 'Energiebron',
			verwarmingsprijs: 'Kostprijs energiebron',
			waterprijs: 'Kostprijs',
			aardgasprijs: 'Kostprijs',
			wagen_brandstof: 'Brandstof',
			wagen_brandstofprijs: 'Kostprijs brandstof',
			wagen_groene_stroom: 'Opgeladen met groene stroom',
			wagen_stroomprijs: 'Kostprijs elektriciteit'
		} as Record<ProfileAttribute, string>,
		gezinsleden_eenheden: {
			enkelvoud: 'gezinsleden',
			meervoud: 'gezinslid'
		},
		woningtype_labels: {
			studio_of_klein_appartement: 'Studio of klein appartement',
			gemiddeld_appartement_of_klein_rijhuis:
				'Gemiddeld appartement of klein rijhuis',
			rijhuis_of_groot_appartement: 'Rijhuis of groot appartement',
			groot_rijhuis_of_alleenstaande_woning:
				'Groot rijhuis of alleenstaande woning',
			villa: 'Villa',
			kasteel: 'Kasteel'
		} as Record<WoningType, string>,
		isolatiegraad_labels: {
			niet: 'Niet geïsoleerd',
			slecht:
				'Slecht geïsoleerd (bv. een dunne isolatielaag, geen hoogrendementsglas)',
			redelijk:
				'Redelijk geïsoleerd (bv. een nieuwbouw, 8 cm dakisolatie, meeste ramen met hoogrendementsglas)',
			goed: 'Goed geïsoleerd (bv. een laag-energiehuis met k-waarde lager dan 30)',
			heel_goed:
				'Heel goed geïsoleerd (bv. een passiefhuis met K-waarde lager dan 20)'
		} as Record<IsolatiegraadType, string>,
		verwarmingsinstallatie_labels: {
			oude_verwarmingsinstallatie: 'Oude verwarmingsinstallatie',
			pelletketel: 'Pelletketel',
			hoogrendementsketel: 'Hoogrendementsketel',
			condensatieketel: 'Condensatieketel',
			hybride: 'Hybride: warmtepomp + hoogrendementsketel',
			lucht_warmtepomp: 'Warmtepomp op basis van buitenlucht',
			grondwater_warmtepomp: 'Warmtepomp op basis van grondwater',
			bodemwarmte_warmtepomp: 'Warmtepomp op basis van bodemwarmte'
		} as Record<VerwarmingsInstallatieType, string>,
		verwarmingstype_labels: {
			stookolie: 'Stookolie',
			aardgas: 'Aardgas',
			propaan_butaan: 'Propaan/butaan',
			pellets: 'Pellets',
			elektriciteit: 'Elektriciteit'
		} as Record<VerwarmingsType, string>,
		verwarmingsprijs_eenheden: {
			stookolie: '/ liter',
			aardgas: '/ kWh',
			propaan_butaan: '/ liter',
			pellets: '/ kg',
			elektriciteit: '/ kWh'
		} as Record<VerwarmingsType, string>,
		wagen_groene_stroom_labels: {
			altijd: 'Altijd',
			soms: 'Soms',
			nooit: 'Nooit'
		} as Record<WagenGroeneStroomType, string>,
		wagen_brandstof_labels: {
			diesel: 'Diesel',
			benzine: 'Benzine',
			lpg: 'LPG',
			cng: 'CNG',
			waterstof: 'Waterstof'
		} as Record<WagenBrandstofType, string>,
		wagen_brandstofprijs_eenheden: {
			diesel: '/ liter',
			benzine: '/ liter',
			lpg: '/ liter',
			cng: '/ kg',
			waterstof: '/ kg'
		} as Record<WagenBrandstofType, string>,
		product_profiles: {
			title: 'Producteigenschappen',
			link: 'Productpagina bekijken',
			save: 'Opslaan product details',
			categories: {
				home: 'Woning',
				electro: 'Elektro',
				movements: 'Verplaatsingen'
			}
		}
	},
	calculator: {
		submit: 'Ok',
		enter: "of druk op 'Enter'",
		previous: 'Vorige',
		next: 'Volgende',
		restart: 'Herbeginnen',
		materiaal: 'Materiaal:',
		dikte: 'Dikte:',
		dit_komt_overeen_met: 'Dit komt overeen met',
		verbruik_personaliseren: 'Mijn profiel personaliseren',
		verbruik_aanpassen: 'Mijn profiel aanpassen',
		besparing_personaliseren: 'Besparing personaliseren',
		besparing_aanpassen: 'Mijn profiel aanpassen',
		toestel_kiezen: 'Toestel kiezen',
		toestel_aanpassen: 'Toestel aanpassen',
		wagen_kiezen: 'Wagen kiezen',
		wagen_aanpassen: 'Wagen aanpassen',
		close_comparison: 'Vergelijking sluiten',
		after_march_2021: 'na maart 2021',
		before_march_2021: 'voor maart 2021',
		dak: {
			lengte_vloer: 'De lengte van de vloer (onder het ganse dak):',
			breedte_vloer: 'De breedte van de vloer (onder het ganse dak):',
			hoogte_tot_nok: 'De hoogte tot de nok:',
			lengte_nok: 'De lengte van de nok:',
			oppervlakte_dakvensters: 'De oppervlakte van alle dakvensters samen:',
			nok_righting: 'Hoe loopt de nok?',
			nok_righting_options: {
				lengte: 'Evenwijdig met de langste gevel (lengte)',
				breedte: 'Evenwijdig met de kortste gevel (breedte)'
			},
			oppervlakte_dak:
				'De oppervlakte van je hellend dak (zonder dakvensters) bedraagt:',
			dak_long: 'Long',
			dak_large: 'Large'
		},
		vensters: {
			oppervlakte_enkel: 'Oppervlakte enkel glas:',
			oppervlakte_dubbel: 'Oppervlakte dubbel glas:'
		},
		glas_labels: {
			hoogrendementsglas: ['Hoogrendementsglas', 'hoog-rendement'],
			driedubbelglas: ['Driedubbelglas', 'driedubbel']
		} as Record<GlasType, [string, string]>,
		raamkader_labels: {
			PUR: 'PUR',
			PVC: 'PVC',
			aluminium: 'aluminium',
			hout: 'hout',
			staal: 'staal'
		} as Record<RaamkaderMateriaal, string>,
		oven_energiebronnen: {
			elektriciteit: 'Elektriciteit',
			gas: 'Gas'
		} as Record<OvenEnergiebron, string>,
		oven_attributes: {
			hoogte: 'Hoogte:',
			breedte: 'Breedte:'
		} as Record<OvenDimensie, string>,
		oven_eenheden: {
			uur_per_bakbeurt: 'uur per bakbeurt',
			keer_per_jaar: 'keer per jaar'
		},
		kookplaten_eenheden: {
			uur_per_week: 'uur per week',
			pitten: 'pitten'
		},
		wasmachine_gebruiken: {
			ja: 'Ja, bijna altijd',
			soms: 'Ja, af en toe',
			nee: 'Nee, nooit'
		},
		wasmachine_capaciteiten: {
			5: 'tot 5 kg',
			6: '6 kg',
			7: '7 kg',
			8: '8 kg',
			9: '9 kg',
			10: '10 kg',
			11: 'meer dan 10 kg'
		} as Record<WasmachineCapaciteit, string>,
		vaatwasser_capaciteiten: {
			6: '6',
			7: '7',
			8: '8',
			9: '9',
			10: '10',
			11: '11',
			12: '12',
			13: '13',
			14: '14',
			15: '15',
			16: '16'
		} as Record<VaatwasserCapaciteit, string>,
		droogkast_capaciteiten: {
			7: '7 kg',
			8: '8 kg',
			9: '9 kg',
			10: '10 kg of meer'
		} as Record<DroogkastCapaciteit, string>,
		wagen_rijstijlen: {
			zuinig: 'Erg zuinig',
			sportief: 'Sportief',
			ik_let_er_niet_op: 'Ik let er niet op'
		} as Record<Rijstijl, string>,
		units: {
			kwh: _ => 'kWh',
			co2: _ => 'kg CO₂',
			euro: _ => 'euro',
			ballon: a => (Math.abs(a) > 1 ? 'ballonnen' : 'ballon'),
			tankwagen: a => (Math.abs(a) > 1 ? 'tankwagens' : 'tankwagen'),
			m2: _ => 'm²',
			dikte: _ => 'cm',
			liter: _ => 'liter'
		} as Record<CalculationUnit | 'liter', (amount: number) => string>,
		energy_label: {
			A: 'A',
			B: 'B',
			C: 'C',
			D: 'D',
			E: 'E',
			F: 'F',
			G: 'G',
			oud_A_ppp: 'A+++',
			oud_A_pp: 'A++',
			oud_A_p: 'A+',
			oud_A: 'A',
			oud_B: 'B',
			oud_C: 'C',
			oud_D: 'D',
			oud_onbekend: 'Een oud toestel, maar ik ken het energielabel niet'
		} as Record<EnergieLabel, string>,
		muurtypes: {
			massief: 'Een massieve muur',
			spouwmuur: 'Een spouwmuur'
		} as Record<MuurType, string>,
		isolatie_positie: {
			binnen: 'Langs binnen',
			spouw: 'In de spouw',
			buiten: 'Langs buiten'
		} as Record<MuurIsolatiePositie, string>,
		yes: 'Ja',
		no: 'Nee',
		hellend_dak_types: {
			zadeldak: 'Zadeldak',
			geveldak: 'Geveldak',
			wolfsdak: 'Wolfsdak',
			lessenaarsdak: 'Lessenaarsdak',
			mansardedak: 'Mansardedak',
			schilddak: 'Schilddak',
			puntdak: 'Puntdak'
		} as Record<HellendDakType, string>
	},
	error404: {
		title: 'Pagina niet gevonden',
		text: 'De pagina die je probeert te bereiken is niet beschikbaar of is onlangs verwijderd.',
		btn: 'Ga naar startpagina'
	},
	error500: {
		title: 'Een error',
		text: 'Er tradt een error op bij het genereren van de pagina. Probeer het later opnieuw.',
		btn: 'Ga naar startpagina'
	},
	autogids: {
		vorm: {
			stadswagen: 'Stadswagen',
			berline: 'Berline (familiewagen)',
			break: 'Break',
			monovolume: 'Monovolume',
			suv: 'SUV en 4x4',
			minibus: 'Minibus',
			coupe: 'Coupé',
			cabrio: 'Cabrio',
			roadster: 'Roadster',
			sportief: 'Ultra-sportief',
			limousine: 'Limousine'
		} as Record<AutogidsVorm, string>,
		aandrijving: {
			cat: {
				elektrisch: '100% elektrisch',
				hybride: 'Hybride',
				verbranding: 'Verbrandingsmotor'
			} as Record<AutogidsAandrijvingCategory, string>,
			subcat: {
				elektrisch_batterij: 'batterij<br>(BEV)',
				elektrisch_brandstof: 'brandstofcel<br>(FCEV)',
				hybride_plug: 'plug-in hybride<br>(PHEV)',
				hybride_zelf: 'zelfopladende<br>hybride (HEV)',
				verbranding_vloeibaar: 'vloeibare<br>brandstof (ICE)',
				verbranding_gas: 'gasvormige<br>brandstof (ICE)'
			} as Record<AutogidsAandrijvingSubcategory, string>,
			bron: {
				elektrisch_batterij_e: 'elektriciteit',
				elektrisch_brandstof_h: 'waterstof',
				hybride_plug_be: 'benzine + elektriciteit',
				hybride_plug_de: 'diesel + elektriciteit',
				hybride_zelf_be: 'benzine + elektriciteit',
				hybride_zelf_de: 'diesel + elektriciteit',
				verbranding_vloeibaar_b: 'benzine',
				verbranding_vloeibaar_d: 'diesel',
				verbranding_gas_lpg: 'LPG',
				verbranding_gas_aardgas: 'aardgas (CNG)'
			} as Record<AutogidsAandrijvingBron, string>
		},
		uitstoot: {
			category: 'Categorie',
			max: 'Maximaal',
			unit: 'g/km'
		},
		terms: {
			merk_model: 'Merk en model',
			search_placeholder: 'Typ de naam van een merk en/of model',
			vorm: 'Type',
			aandrijving: 'Aandrijving',
			uitstoot: 'CO₂-uitstoot',
			resultaat: ' resultaat',
			resultaten: ' resultaten',
			resultaat_voor: " resultaat voor '<term>'",
			resultaten_voor: " resultaten voor '<term>'",
			compare: 'Vergelijken',
			reset: 'Herbeginnen',
			yes: 'ja',
			no: 'nee'
		},
		tables: {
			title: {
				elektrisch: '100% elektrische wagens',
				hybride: 'Hybride wagens',
				verbranding: 'Wagens met uitsluitend verbrandingsmotoren'
			},
			text: 'Selecteer in de tabellen de wagens die je wil vergelijken. Je kan tot 3 wagens gelijktijdig met elkaar vergelijken.',
			terms: {
				merk: 'merk',
				model: 'model',
				capaciteit: 'capaciteit',
				vermogen: 'vermogen',
				vermogen_unit: 'fisc. pK',
				verbruik: 'verbruik',
				aandrijving: 'aandrijving',
				uitstoot: 'CO₂-uitstoot',
				autonomie: 'autonomie',
				plug: 'plug-in?',
				euronorm: 'euronorm',
				euronorm_split: 'Euro- norm',
				brandstof: 'brandstof',
				elektriciteit: 'elektriciteit',
				waterstof: 'waterstof',
				categorie: 'categorie',
				nox: 'NOₓ-uitstoot'
			}
		},
		compare: {
			title: 'Vergelijking van jouw selectie'
		}
	},
	emails: {
		general: {
			slogan:
				'De Belgische federale site<br>om je CO<sub>2</sub>-uitstoot te verlagen',
			link: 'Naar de website',
			greenhosting:
				'Voor de hosting van onze webtoepassingen maken we gebruik van groene stroom',
			climate_link: 'https://klimaat.be',
			climate2050_link: 'https://klimaat.be/2050-nl',
			twitter_link: 'https://twitter.com/klimaat_be',
			linkedin_link: 'https://www.linkedin.com/company/belgium-climate-change',
			privacy_statement: 'Juridische mededelingen',
			privacy_link: 'juridische-mededelingen'
		},
		signup: {
			subject: 'Activeer je EnergyWatchers account',
			title: 'Activeer je account',
			message:
				'Bedankt voor het aanmaken van je account op EnergyWatchers.<br>Klik op de link onderaan om je account te activeren.',
			button: 'Activeer je account'
		},
		forgot_password: {
			subject: 'Herstel je EnergyWatchers wachtwoord',
			og_title: 'Herstel je EnergyWatchers wachtwoord',
			title: 'Herstel je wachtwoord',
			message: 'Klik op deze link om je wachtwoord te herstellen.',
			button: 'Herstel je wachtwoord'
		}
	}
}
