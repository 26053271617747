import {Icon, IconKey} from 'layout/Icon'
import React, {useRef, useState} from 'react'

import AnimateHeight from 'react-animate-height'
import {CMSLink} from 'cms-types'
import {Container} from 'layout/Container'
import {Link} from 'layout/Link'
import css from './Navigation.module.scss'
import {fromModule} from 'util/styler/Styler'
import {slugify} from 'util/slugify'
import {useApp} from 'AppContext'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

function useHover() {
	const setHoveringTimout = useRef(null)
	const [isHovering, setIsHovering] = useState<boolean>(false)
	const props = {
		onMouseEnter: () => {
			clearTimeout(setHoveringTimout.current)
			setHoveringTimout.current = setTimeout(() => setIsHovering(true), 150)
		},
		onFocus: () => {
			clearTimeout(setHoveringTimout.current)
			setIsHovering(true)
		},
		onMouseLeave: () => {
			clearTimeout(setHoveringTimout.current)
			setIsHovering(false)
		},
		onBlur: () => {
			clearTimeout(setHoveringTimout.current)
			setHoveringTimout.current = setTimeout(() => setIsHovering(false), 150)
		}
	}
	return {
		isHovering,
		setIsHovering,
		props
	}
}

export const Navigation: React.FC = () => {
	const {props, isHovering, setIsHovering} = useHover()
	const t = useTranslation()
	const {general} = useApp()
	const {products, nav, search} = general?.header
	if (!products?.length && !nav?.length) return null

	return (
		<div className={styles.navigation.is({open: isHovering})()}>
			<div className={styles.navigation.nav()}>
				<Container>
					<nav className={styles.navigation.nav.row()}>
						<ul className={styles.navigation.nav.row.left()} {...props}>
							{products.map((product, i) => {
								return (
									<NavigationItem
										{...product}
										isOpen={isHovering}
										onClose={() => setIsHovering(false)}
										key={i}
									/>
								)
							})}
						</ul>
						<ul className={styles.navigation.nav.row.right()}>
							{nav?.length &&
								nav.map((link, i) => (
									<li
										className={styles.navigation.nav.row.right.item()}
										key={i}
									>
										<NavigationLink {...link} />
									</li>
								))}
							<li className={styles.navigation.nav.row.right.item()}>
								<Link
									tabIndex={0}
									to={search ? search : t.menu.search.url}
									className={styles.navigation.nav.row.right.item.search()}
									aria-label="Search"
								>
									<Icon icon="search_regular" />
								</Link>
							</li>
						</ul>
					</nav>
				</Container>
			</div>
		</div>
	)
}

const NavigationItem: React.FC<{
	icon: string
	title: string
	items: Array<{
		icon: string
		link: CMSLink
	}>
	isOpen?: boolean
	onClose: () => void
}> = ({icon, title, items, isOpen, onClose}) => {
	const router = useRouter()
	const isActive = router?.asPath.includes(slugify(title))
	const activeIcon = isActive ? icon.replace('_light', '_regular') : icon

	return (
		<li className={styles.item()}>
			<div className={styles.item.category.is({active: isActive})()}>
				{icon && (
					<span className={styles.item.category.icon()}>
						<Icon icon={activeIcon as IconKey} />
					</span>
				)}
				{title}
			</div>
			<AnimateHeight
				height={isOpen ? 'auto' : 0}
				animateOpacity={true}
				contentClassName="navigation-item-wrapper"
				aria-hidden={false}
			>
				<ul className={styles.item.links()}>
					{(items || []).map(
						(product, i) =>
							product?.link?.url && (
								<li className={styles.item.links.item()} key={i}>
									<NavigationProduct {...product} onClose={onClose} />
								</li>
							)
					)}
				</ul>
			</AnimateHeight>
		</li>
	)
}

const NavigationProduct: React.FC<{
	icon: string
	link: CMSLink
	onClose?: () => void
}> = ({icon, link, onClose}) => {
	const router = useRouter()
	if (!link?.url) return null

	const isActive =
		router?.asPath === link.url || router?.asPath.includes(link.url)

	return (
		<Link
			to={link.url}
			onClick={onClose}
			className={styles.product.mod({icon: icon}).is({
				active: isActive
			})()}
		>
			{icon && (
				<span className={styles.product.icon()}>
					<Icon icon={icon as IconKey} mod="maxwidth" />
				</span>
			)}
			<span className={styles.product.text()}>
				<span className={styles.product.text.border()}>
					{link.description || link.title}
				</span>
			</span>
		</Link>
	)
}

const NavigationLink: React.FC<{
	icon: string
	link: CMSLink
}> = ({icon, link}) => {
	const router = useRouter()
	if (!link?.url) return null

	const isActive =
		router?.asPath === link.url || router?.asPath.includes(link.url)
	const activeIcon = isActive ? icon.replace('_light', '_regular') : icon

	return (
		<Link to={link.url} className={styles.link.is({active: isActive})()}>
			{icon && (
				<span className={styles.link.icon()}>
					<Icon icon={activeIcon as IconKey} />
				</span>
			)}
			{link.description || link.title}
		</Link>
	)
}
