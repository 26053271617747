export const oudeEnergieLabels = [
	'oud_A_ppp',
	'oud_A_pp',
	'oud_A_p',
	'oud_A',
	'oud_B',
	'oud_C',
	'oud_D'
] as const

export type EnergieLabelOud = typeof oudeEnergieLabels[number]

export const nieuweEnergieLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G'] as const

export type EnergieLabelNieuw = typeof nieuweEnergieLabels[number]

export type EnergieLabel = EnergieLabelOud | 'oud_onbekend' | EnergieLabelNieuw

export const productOudeLabels: EnergieLabel[] = [
	'oud_A_ppp',
	'oud_A_pp',
	'oud_A_p',
	'oud_A',
	'oud_B',
	'oud_C',
	'oud_D',
	'oud_onbekend'
]

export const energieLabelColors: Record<EnergieLabel, string> = {
	A: '#00A652',
	B: '#52B748',
	C: '#BDD730',
	D: '#FFF200',
	E: '#FEB814',
	F: '#F37021',
	G: '#ED1B24',
	oud_A_ppp: '#00AA47',
	oud_A_pp: '#00BC33',
	oud_A_p: '#B9DA00',
	oud_A: '#FFF400',
	oud_B: '#FFB700',
	oud_C: '#FF6700',
	oud_D: '#FF0006',
	oud_onbekend: '#ED1B24'
}

export const cleanLabel = (label: EnergieLabel) => {
	return label
		.replace('oud_', '[oud] ')
		.replace('_ppp', '+++')
		.replace('_pp', '++')
		.replace('_p', '+')
}
