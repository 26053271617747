import {AppContextType, UserState} from 'AppContext'

import type {ProductKey} from 'account/ProductProfiles'
import assertNever from 'assert-never'

export type FillState = 'incomplete' | 'saved' | 'unsaved'

export type CompareState<K extends string, S> = {
	open: boolean
	step: K
	state: Partial<S>
	fill_B: FillState
	fill_C: FillState
}

export type FlyoutAction<Key extends string, Action> =
	| {type: 'compare_close'; app: AppContextType}
	| {type: 'compare_toggle'}
	| {type: 'compare_restart'}
	| {type: 'compare_set_step'; step: Key}
	| {type: 'compare_update'; action: Action; app: AppContextType}
	| {type: 'compare_reset_unsaved'}

export type CompareCState<K extends string, S, C> = {
	profile_open: boolean
	config: C
	compare: CompareState<K, S>
}

export type CompareABState<K extends string, S, C> = {
	profile_open: boolean
	new_step: K
	new: Partial<S>
	config: C
	compare: CompareState<K, S>
	fill_A: FillState
}

export type CompareCAction<K extends string, A> =
	| {type: 'init_user'; user: UserState}
	| {type: 'profile_toggle'}
	| FlyoutAction<K, A>

export type CompareABAction<K extends string, A> =
	| {type: 'restart'}
	| {type: 'init_user'; user: UserState}
	| {type: 'set_step'; step: K}
	| {type: 'update'; action: A}
	| {type: 'profile_toggle'}
	| {type: 'reset_unsaved'}
	| FlyoutAction<K, A>

export function stepsFilter<Step extends string>(
	input: readonly Step[],
	predicate: (step: Step) => unknown
): readonly Step[] {
	let isSkipped = false

	return input.filter(step => {
		const keep = !!predicate(step)
		if (!keep) isSkipped = true
		return keep && !isSkipped
	})
}

export function createDefinition<
	K extends string,
	S extends {product: 'A' | 'B' | 'C'},
	A,
	C,
	PR extends ProductKey = ProductKey
>(def: {
	product: PR
	steps: readonly K[]
	onChange: (state: Partial<S>, action: A, config: C) => Partial<S>
	getValidSteps: (state: Partial<S>) => readonly K[]
	getSelectableSteps: (state: Partial<S>) => readonly K[]
	defaultState: (product: 'A' | 'B' | 'C', config: C) => Partial<S>
	defaultProfileState?: (product: 'A', config: C) => Partial<S>
	isComplete: (state: Partial<S>) => state is S
	gebruiksparameters?: (keyof S)[]
}) {
	const syncGebruikersparameters = (
		stateB: Partial<S>,
		stateA: Partial<S>
	): Partial<S> => {
		return {
			...stateB,
			...(def.gebruiksparameters || []).reduce((acc, param) => {
				return {
					...acc,
					[param]: stateA[param]
				}
			}, {})
		}
	}

	const shouldStartSyncing = (state: Partial<S>, step: K) => {
		if (!def.isComplete(state)) return false
		const steps = def.getValidSteps(state)
		return step === steps[steps.length - 1]
	}

	const initAankooptip = (config: C): CompareABState<K, S, C> => {
		const initial = def.defaultState('A', config)
		const initialB = def.defaultState('B', config)
		const firstStepA = def.getValidSteps(initial)[0]
		const firstStepB = def.getValidSteps({product: 'B'} as S)[0]
		return {
			profile_open: false,
			new_step: firstStepA,
			new: initial,
			compare: {
				open: false,
				step: firstStepB,
				state: {
					...initialB,
					...syncGebruikersparameters({}, initial),
					product: 'B'
				} as S,
				fill_B: 'incomplete',
				fill_C: 'incomplete'
			},
			fill_A: 'incomplete',
			config
		}
	}

	const handleFlyoutAction = (
		state: CompareState<K, S>,
		action: FlyoutAction<K, A>,
		config: C
	) => {
		const product = state.state.product as 'B' | 'C'
		const fill = state[`fill_${product}`]
		switch (action.type) {
			case 'compare_close':
				return {...state, open: false}
			case 'compare_toggle':
				return {...state, open: !state.open}
			case 'compare_set_step':
				let newFill: FillState = 'incomplete'
				if (fill === 'saved') newFill = 'saved'
				else if (shouldStartSyncing(state.state, action.step))
					newFill = 'unsaved'
				return {
					...state,
					[`fill_${product}`]: newFill,
					step: action.step
				}
			case 'compare_restart':
				const initial = def.defaultState(state.state.product, config)
				const firstStep = def.getValidSteps(initial)[0]
				return {...state, open: true, step: firstStep, state: initial}
			case 'compare_update':
				const newState = def.onChange(state.state, action.action, config)
				return {
					...state,
					state: newState,
					[`fill_${product}`]:
						(def.isComplete(newState) && fill === 'saved') ||
						shouldStartSyncing(newState, state.step)
							? 'unsaved'
							: fill
				}
			case 'compare_reset_unsaved':
				return {...state, [`fill_${product}`]: 'saved'}
			default:
				assertNever(action)
		}
	}

	const reducerAankooptip = (
		state: CompareABState<K, S, C>,
		action: CompareABAction<K, A>
	): CompareABState<K, S, C> => {
		switch (action.type) {
			case 'restart':
				return initAankooptip(state.config)
			case 'init_user':
				const productA = action.user.products_A?.[def.product] as S
				const productB = action.user.products_B?.[def.product] as S

				return {
					...state,
					new: productA || state.new,
					compare: {
						...state.compare,
						state: syncGebruikersparameters(
							productB || state.compare.state,
							productA || state.new
						),
						fill_B: productB ? 'saved' : 'incomplete'
					},
					fill_A: productA ? 'saved' : 'incomplete'
				}
			case 'profile_toggle':
				return {...state, profile_open: !state.profile_open}
			case 'set_step':
				let fill_A: FillState = 'incomplete'
				if (state.fill_A === 'saved') fill_A = 'saved'
				else if (shouldStartSyncing(state.new, action.step)) fill_A = 'unsaved'
				return {
					...state,
					fill_A,
					new_step: action.step
				}
			case 'reset_unsaved':
				return {...state, fill_A: 'saved'}
			case 'update':
				const newState = def.onChange(state.new, action.action, state.config)
				return {
					...state,
					new: newState,
					fill_A:
						(def.isComplete(newState) && state.fill_A === 'saved') ||
						shouldStartSyncing(newState, state.new_step)
							? 'unsaved'
							: state.fill_A,
					compare: {
						...state.compare,
						state: syncGebruikersparameters(state.compare.state, newState)
					}
				}
			default:
				const compare = handleFlyoutAction(state.compare, action, state.config)
				return {
					...state,
					compare: {
						...compare,
						state: syncGebruikersparameters(compare.state, state.new)
					}
				}
		}
	}

	const handleUnsaved = (
		app: AppContextType,
		state: Partial<S>,
		fill: FillState,
		dispatch: (state: CompareABAction<K, A>) => void
	) => {
		if (fill !== 'unsaved') return
		const product = state.product
		app.updateProduct(product, {[def.product]: state})
		if (product === 'A') {
			dispatch({type: 'reset_unsaved'})
			return
		}
		dispatch({type: 'compare_reset_unsaved'})
	}

	return {
		...def,
		initCompareGedragstip: (config: C) => {
			const initial = def.defaultState('C', config)
			const firstStepC = def.getValidSteps(initial)[0]
			return {
				open: false,
				product: 'C' as const,
				step: firstStepC,
				state: initial,
				fill_B: 'incomplete' as FillState,
				fill_C: 'incomplete' as FillState
			}
		},
		initAankooptip,
		reducerAankooptip,
		handleFlyoutAction,
		handleUnsaved
	}
}
