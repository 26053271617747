import {Icon, IconKey} from 'layout/Icon'
import React, {Fragment, useEffect, useState} from 'react'

import {Button} from 'layout/Button'
import {CMSWysiwyg} from 'cms-types'
import {Container} from '../Container'
import {Link} from 'layout/Link'
import {LinkStyled} from 'layout/LinkStyled'
import {Mobilemenu} from './Mobilemenu'
import NextImage from 'next/image'
import {Trusted} from 'layout/Trusted'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Header.module.scss'
import {fromModule} from 'util/styler/Styler'
import {toastify} from 'util/toastify'
import {useApp} from 'AppContext'
import {useMediaQuery} from 'util/mediaquery'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const Header: React.FC = () => {
	const [isMounted, setIsMounted] = useState<boolean>(false)
	const [toastVisible, setToastVisible] = useState<boolean>(true)
	const isIpadLand = useMediaQuery('(min-width: 1024px)')

	const app = useApp()
	const isDirty = app.user.isUpdated && !app.account
	const general = app.general
	const {slogan} = general?.header

	useEffect(() => {
		setIsMounted(true)
	}, [])

	useEffect(() => {
		if (app.loaded) {
			setToastVisible(!isDirty)
		}
	}, [app.loaded, isDirty])

	return (
		<div className={styles.header()}>
			<Container>
				<div className={styles.header.row()}>
					<HeaderLogo slogan={slogan} />
					<div className={styles.header.row.right()}>
						<HeaderAccount isDirty={isDirty} loggedIn={!!app.account} />
						<HeaderHamburger />
						<HeaderSubnav
							isDirty={isDirty}
							isMounted={isMounted}
							toastVisible={toastVisible}
							setToastVisible={setToastVisible}
						/>
						{!isIpadLand && (
							<HeaderToast
								isDirty={isDirty}
								toastVisible={toastVisible}
								setToastVisible={setToastVisible}
							/>
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}

const HeaderLogo: React.FC<{slogan?: CMSWysiwyg}> = ({slogan}) => {
	const {lang} = useApp()
	const t = useTranslation()

	return (
		<div className={styles.logo()}>
			<Link
				to={lang ? `/${lang}` : '/'}
				tabIndex={0}
				title={t.projectname}
				className={styles.logo.img()}
			>
				<NextImage
					priority
					src={'/logo.svg'}
					alt="EnergyWatchers logo"
					width="206.45"
					height="56.79"
				/>
			</Link>
			{slogan && <Wysiwyg className={styles.logo.slogan()}>{slogan}</Wysiwyg>}
		</div>
	)
}

const HeaderSubnav: React.FC<{
	isDirty: boolean
	isMounted: boolean
	toastVisible: boolean
	setToastVisible: (boolean) => void
}> = ({isDirty, isMounted, toastVisible, setToastVisible}) => {
	const app = useApp()
	const {subnav} = app.general?.header
	const router = useRouter()
	const t = useTranslation()
	const isIpadLand = useMediaQuery('(min-width: 1024px)')

	const accountUrl = t.menu.account.url + (isDirty ? '?tab=account_login' : '')

	return (
		<nav className={styles.subnav()}>
			{subnav?.length > 0 && (
				<ul className={styles.subnav.links()}>
					{subnav.map((item, i) => {
						if (!item.link) return null

						return (
							<li className={styles.subnav.links.item()} key={i}>
								<Link
									to={item.link.url}
									className={styles.subnav.links.item.link.mod(item.icon)()}
									aria-label={item.link.description || item.link.title}
								>
									<Icon icon={item.icon as IconKey} />
									<div className={styles.subnav.links.item.link.tooltip()}>
										{item.link.description || item.link.title}
									</div>
								</Link>
							</li>
						)
					})}
				</ul>
			)}
			{app.account && (
				<LinkStyled
					onClick={async () => {
						app.logout()
						const toast = await toastify()
						toast.warning(t.account.toasts.logged_out)
					}}
					iconbefore="sign_out_regular"
					className={styles.subnav.logout()}
					mod={['grey', 'regular']}
				>
					{t.account.logout}
				</LinkStyled>
			)}
			<div className={styles.subnav.button()}>
				<Button
					to={accountUrl}
					iconbefore="user_circle_solid"
					className={styles.subnav.button.btn()}
					mod={['purple', 'small']}
					isDirty={isDirty}
					onClick={e => {
						setToastVisible(false)
					}}
				>
					{t.menu.account.title}
					{app.account && (
						<span className={styles.subnav.button.btn.profile()}>
							{app.account.name}
						</span>
					)}
				</Button>
				{isIpadLand && (
					<HeaderToast
						isDirty={isDirty}
						toastVisible={toastVisible}
						setToastVisible={setToastVisible}
					/>
				)}
			</div>
		</nav>
	)
}

const HeaderToast: React.FC<{
	isDirty?: boolean
	toastVisible?: boolean
	setToastVisible: (boolean) => void
}> = ({isDirty, toastVisible, setToastVisible}) => {
	const t = useTranslation()
	const accountUrl = t.menu.account.url + (isDirty ? '?tab=account_login' : '')
	const isVisible = isDirty && toastVisible

	return (
		<div
			aria-hidden={!isVisible}
			className={styles.toast.is({visible: isVisible})()}
		>
			<Link
				tabIndex={-1}
				to={accountUrl}
				onClick={() => setToastVisible(false)}
				className={styles.toast.link()}
			>
				<Trusted className={styles.toast.link.text()}>
					{t.account.header_toast}
				</Trusted>
				<span className={styles.toast.link.icon()}>
					<Icon icon="pointer_click" />
				</span>
			</Link>
			<button
				tabIndex={-1}
				onClick={() => setToastVisible(false)}
				className={styles.toast.close()}
				aria-label="Close"
			>
				<Icon icon="times_light" />
			</button>
		</div>
	)
}

const HeaderHamburger: React.FC = () => {
	const [isOpen, setOpen] = useState<boolean>(false)

	return (
		<Fragment>
			<button
				onClick={() => setOpen(true)}
				aria-label="Open mobile menu"
				aria-expanded={isOpen ? true : false}
				className={styles.hamburger()}
			>
				<div className={styles.hamburger.bars()}>
					<span className={styles.hamburger.bars.bar()} />
					<span className={styles.hamburger.bars.bar()} />
					<span className={styles.hamburger.bars.bar()} />
				</div>
			</button>
			<Mobilemenu isOpen={isOpen} onClose={() => setOpen(false)} />
		</Fragment>
	)
}

const HeaderAccount: React.FC<{
	isDirty: boolean
	loggedIn: boolean
}> = ({isDirty, loggedIn}) => {
	const t = useTranslation()
	const accountUrl = t.menu.account.url + (isDirty ? '?tab=account_login' : '')

	if (!loggedIn) return null

	return (
		<Link to={accountUrl} className={styles.account()}>
			<Icon icon="user_circle_solid" />
		</Link>
	)
}
