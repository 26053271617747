import React, {useState} from 'react'
import {randomFromArray, randomIntFromInterval} from 'util/array'

import {Copyright} from './footer/Copyright'
import {Doormat} from './footer/Doormat'
import {Federalheader} from './header/Federalheader'
import {Footer} from './footer/Footer'
import {Header} from './header/Header'
import {Icon} from './Icon'
import {Navigation} from './header/Navigation'
import css from './Layout.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

type LayoutIconData = {
	type: 'euro' | 'lightning' | 'thumb' | 'tint'
	size: 'medium' | 'small' | 'large'
	right: number
	speed: number
	delay: number
	wiggle: number
}

export const Layout: React.FC = ({children}) => {
	const {node} = useApp()
	return (
		<div className={styles.layout.mod(node?.channel)()}>
			<header>
				<Federalheader />
				<Header />
				<Navigation />
			</header>
			<main>
				<div suppressHydrationWarning>
					{node?.channel === 'home' && process.browser && <LayoutIcons />}
				</div>
				{children}
			</main>
			<footer>
				<Doormat />
				<Footer />
				<Copyright />
			</footer>
		</div>
	)
}

const LayoutIcons: React.FC = () => {
	const typeArray = ['euro', 'lightning', 'thumb', 'tint']
	const sizeArray = ['medium', 'small', 'large']
	const [icons] = useState(() =>
		[...Array(25)].map(() => ({
			type: randomFromArray(typeArray),
			size: randomFromArray(sizeArray),
			right: randomIntFromInterval(125, 500),
			speed: randomIntFromInterval(1, 5),
			delay: randomIntFromInterval(500, 2500),
			wiggle: randomIntFromInterval(1, 4)
		}))
	)

	return (
		<div className={styles.icons()}>
			{icons.map((icon, i) => (
				<LayoutIcon key={i} {...icon} />
			))}
		</div>
	)
}

const LayoutIcon: React.FC<LayoutIconData> = ({
	type,
	size,
	right,
	speed,
	delay,
	wiggle
}) => {
	const speedArr = [4, 3.25, 2.75, 2]
	return (
		<div
			className={styles.icon.mod(type).mod(size)()}
			style={{
				right: `${right}px`,
				animation: `${css['wiggle' + wiggle]} ${speedArr[speed]}s linear`,
				animationDelay: `${delay}ms`
			}}
		>
			<Icon icon={`animation_${type}`} />
		</div>
	)
}
