import React, {HTMLProps} from 'react'

import NextLink from 'next/link'
import {UrlObject} from 'url'
import {useRouter} from 'next/router'

export function checkIsExternal(href: string) {
	if (
		href.startsWith &&
		(!href.startsWith('http') || !href.startsWith('https'))
	)
		return false
	return true
}

export function checkIsFile(href: string) {
	if (href.split('/').pop().includes('.')) return true
}

export type IProps = Omit<
	| HTMLProps<HTMLAnchorElement>
	| ({as: 'button'} & HTMLProps<HTMLButtonElement>),
	'className'
> & {
	to?: string | UrlObject
	className?: string
}

export const Link = React.forwardRef(function Link(
	{children, as, to, target, ...props}: IProps,
	ref: any
) {
	const Comp = as || 'a'
	const router = useRouter()

	if (!to) {
		return (
			<Comp {...(props as any)} ref={ref}>
				{children}
			</Comp>
		)
	}

	if (typeof to !== 'string') {
		return (
			<NextLink href={to} prefetch={false}>
				<a {...(props as any)} ref={ref} target={target || '_self'}>
					{children}
				</a>
			</NextLink>
		)
	}

	let href = to
	const isExternal = checkIsExternal(href)
	const isFile = checkIsFile(href)
	if (href && (isExternal || isFile)) {
		return (
			<a
				{...(props as any)}
				href={href}
				ref={ref}
				target={target || '_blank'}
				rel={isExternal ? 'external nofollow noopener' : null}
			>
				{children}
			</a>
		)
	}

	const anchorPieces = href.split('#')
	const anchor = href.startsWith('#') ? href.slice(1) : anchorPieces[1]
	const isInpageAnchor =
		href.includes('#') &&
		(href.startsWith('#') || anchorPieces[0] === router.asPath)
	if (isInpageAnchor) {
		return (
			<Comp
				{...(props as any)}
				ref={ref}
				target={'_self'}
				onClick={e => {
					e.preventDefault()
					scrollToHash(anchor)
				}}
			>
				{children}
			</Comp>
		)
	}

	return (
		<NextLink href={href} prefetch={false}>
			<a {...(props as any)} ref={ref} target={target || '_self'}>
				{children}
			</a>
		</NextLink>
	)
})

export function scrollToHash(to, yOffset = -20) {
	if (!to) return

	const hash = to.substring(0, 1) === '#' ? to.substring(1) : to
	if (!hash) return

	const el: HTMLElement | null = document.getElementById(hash)
	if (!el) return
	const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset

	setTimeout(() => window.scrollTo({top: y, behavior: 'smooth'}), 0)
}
