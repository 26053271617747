/*
 * shuffle
 * @param {Array} array - List of elements
 * @return {Array} Returns list of shuffled elements
 */
export function shuffle(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1))
		var temp = array[i]
		array[i] = array[j]
		array[j] = temp
	}
}

export function randomFromArray(array) {
	return array[Math.floor(Math.random() * array.length)]
}

export function randomIntFromInterval(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min)
}

export function toggleArray(
	arr: Array<number | string>,
	item: number | string
) {
	const newArr = arr.slice()
	const arrIndex = newArr.indexOf(item)
	if (arrIndex < 0) newArr.push(item)
	else newArr.splice(arrIndex, 1)
	return newArr
}
