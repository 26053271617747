import {Container} from 'layout/Container'
import {Link} from 'layout/Link'
import NextImage from 'next/image'
import React from 'react'
import css from './Federalheader.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const Federalheader: React.FC = () => {
	return (
		<div className={styles.federalheader()}>
			<Container>
				<div className={styles.federalheader.row()}>
					<FederalheaderLanguages />
					<FederalheaderContent />
				</div>
			</Container>
		</div>
	)
}

const FederalheaderLanguages: React.FC = () => {
	const {lang, node} = useApp()
	const router = useRouter()
	const query = Object.keys(router.query)
		.filter(key => key !== 'slug')
		.reduce((obj, key) => {
			obj[key] = router.query[key]
			return obj
		}, {})
	const switchLangs = [
		{key: 'nl', title: 'Nederlands'},
		{key: 'fr', title: 'Français'}
	]

	return (
		<ul className={styles.languages()}>
			{switchLangs.map((translateLang, i) => {
				return (
					<li
						className={styles.languages.item()}
						aria-label={translateLang.title}
						key={i}
					>
						<Link
							to={{
								pathname:
									node?.translations && node?.translations[translateLang.key]
										? node.translations[translateLang.key]?.url ||
										  node.translations[translateLang.key]
										: `/${translateLang.key}`,
								query
							}}
							tabIndex={0}
							title={translateLang.title}
							className={styles.languages.item.link.is({
								active: lang === translateLang.key
							})()}
						>
							{translateLang.key}
						</Link>
					</li>
				)
			})}
		</ul>
	)
}

const FederalheaderContent: React.FC = () => {
	const t = useTranslation().federalheader

	return (
		<div className={styles.content()}>
			<p className={styles.content.text()}>{t.text}</p>
			<Link
				to={t.link.url}
				title={t.link.url}
				className={styles.content.link()}
			>
				{t.link.title}
			</Link>
			<div className={styles.content.logo()}>
				<NextImage
					priority
					src={'/logo-be.svg'}
					alt="Belgium.be"
					width="32"
					height="23.64"
				/>
			</div>
		</div>
	)
}
