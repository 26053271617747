import {Icon, IconKey} from 'layout/Icon'

import {Container} from '../Container'
import {Link} from 'layout/Link'
import NextImage from 'next/image'
import React from 'react'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Doormat.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const Doormat: React.FC = () => {
	return (
		<div className={styles.doormat()}>
			<Container>
				<div className={styles.doormat.row()}>
					<div className={styles.doormat.left()}>
						<DoormatAbout />
					</div>
					<div className={styles.doormat.right()}>
						<DoormatSubnav />
						<DoormatNav />
					</div>
				</div>
			</Container>
		</div>
	)
}

const DoormatAbout: React.FC = () => {
	const t = useTranslation()
	const {general, lang} = useApp()
	const {slogan, about} = general?.doormat

	return (
		<div className={styles.about()}>
			<Link
				to={lang ? `/${lang}` : '/'}
				tabIndex={0}
				title={t.projectname}
				className={styles.about.logo()}
			>
				<NextImage
					priority
					src={'/logo.svg'}
					alt="Logo"
					width="206.45"
					height="56.79"
				/>
			</Link>
			{slogan && <Wysiwyg className={styles.about.slogan()}>{slogan}</Wysiwyg>}
			{about && <Wysiwyg className={styles.about.text()}>{about}</Wysiwyg>}
		</div>
	)
}

const DoormatSubnav: React.FC = () => {
	const {general} = useApp()
	const {subnav} = general?.doormat

	return (
		<nav className={styles.subnav()}>
			<ul className={styles.subnav.items()}>
				{subnav.map((item, i) => (
					<li className={styles.subnav.items.item()} key={i}>
						<Link
							to={item.link?.url}
							className={styles.subnav.items.item.link()}
						>
							{item.icon && (
								<span className={styles.subnav.items.item.link.icon()}>
									<Icon icon={item.icon as IconKey} />
								</span>
							)}
							<span className={styles.subnav.items.item.link.text()}>
								{item.link?.description || item.link?.title}
							</span>
						</Link>
					</li>
				))}
			</ul>
		</nav>
	)
}

const DoormatNav: React.FC = () => {
	const {general} = useApp()
	const {products} = general?.doormat

	return (
		<nav className={styles.nav()}>
			<ul className={styles.nav.row()}>
				{products.map((product, i) => (
					<li className={styles.nav.item()} key={i}>
						{product.title && (
							<Title.H5 className={styles.nav.item.title()}>
								{product.title}
							</Title.H5>
						)}
						{product.links?.length > 0 && (
							<div className={styles.nav.item.links()}>
								{product.links.map(
									(link, i) =>
										link?.url && (
											<div key={i} className={styles.nav.item.links.item()}>
												<Link
													to={link.url}
													className={styles.nav.item.links.item.link()}
												>
													{link.description || link.title}
												</Link>
											</div>
										)
								)}
							</div>
						)}
					</li>
				))}
			</ul>
		</nav>
	)
}
