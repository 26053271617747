import {Icon, IconKey} from 'layout/Icon'
import React, {useRef, useState} from 'react'

import AnimateHeight from 'react-animate-height'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Link} from 'layout/Link'
import {LinkStyled} from 'layout/LinkStyled'
import {Modal} from 'react-responsive-modal'
import {Title} from 'layout/Title'
import css from './Mobilemenu.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

const overlayStyles = {
	display: 'none'
}

const closeButtonStyles = {
	color: '#fff',
	fontSize: '20px',
	top: '30px',
	right: '30px',
	padding: '10px',
	margin: '-10px'
}

export const Mobilemenu: React.FC<{isOpen?: boolean; onClose?: () => void}> = ({
	isOpen,
	onClose
}) => {
	const modalRef = useRef<HTMLDivElement>(null)

	return (
		<Modal
			center
			open={isOpen}
			onClose={onClose}
			ref={modalRef}
			initialFocusRef={modalRef}
			closeIcon={<Icon icon="times_light" />}
			classNames={{
				modal: styles.mobilemenu()
			}}
			styles={{
				overlay: overlayStyles,
				closeButton: closeButtonStyles
			}}
			aria-hidden={isOpen ? false : true}
		>
			<Container>
				<MobilemenuSubnav onClose={onClose} />
				<MobilemenuAccount onClose={onClose} />
				<MobilemenuNav onClose={onClose} />
			</Container>
		</Modal>
	)
}

const MobilemenuSubnav: React.FC<{onClose?: () => void}> = ({onClose}) => {
	const {lang, general} = useApp()
	const {subnav} = general?.doormat

	return (
		<div className={styles.subnav()}>
			<div className={styles.subnav.item()}>
				<Link
					to={lang ? `/${lang}` : '/'}
					onClick={onClose}
					className={styles.subnav.item.link()}
				>
					<span className={styles.subnav.item.link.icon()}>
						<Icon icon="home_alt_solid" />
					</span>
					<span className={styles.subnav.item.link.text()}>Home</span>
				</Link>
			</div>
			{subnav.map((item, i) => (
				<div key={i} className={styles.subnav.item()}>
					<Link
						to={item.link?.url}
						onClick={onClose}
						className={styles.subnav.item.link()}
					>
						{item.icon && (
							<span className={styles.subnav.item.link.icon()}>
								<Icon icon={item.icon as IconKey} />
							</span>
						)}
						<span className={styles.subnav.item.link.text()}>
							{item.link?.description || item.link?.title}
						</span>
					</Link>
				</div>
			))}
		</div>
	)
}

const MobilemenuAccount: React.FC<{onClose?: () => void}> = ({onClose}) => {
	const app = useApp()
	const router = useRouter()
	const t = useTranslation()
	const dirty = app.user.isUpdated && !app.account
	const accountUrl = t.menu.account.url + (dirty ? '?tab=account_login' : '')

	return (
		<div className={styles.account()}>
			<Button
				to={t.menu.account.url}
				iconbefore="user_circle_solid"
				className={styles.account.button()}
				mod={['white_outline', 'small']}
				isDirty={dirty}
				onClick={e => {
					router.push(accountUrl)
					onClose()
					e.preventDefault()
				}}
			>
				{t.menu.account.title}
				{app.account && (
					<span className={styles.account.button.profile()}>
						{app.account.name}
					</span>
				)}
			</Button>
			{app.account && (
				<LinkStyled
					onClick={() => {
						app.logout()
						onClose()
					}}
					iconbefore="sign_out_regular"
					className={styles.account.logout()}
					mod={['white', 'regular']}
				>
					{t.account.logout}
				</LinkStyled>
			)}
		</div>
	)
}

const MobilemenuNav: React.FC<{onClose?: () => void}> = ({onClose}) => {
	const [isOpen, setOpen] = useState<boolean>(false)
	const {general} = useApp()
	const {products} = general?.header

	return (
		<div className={styles.nav()}>
			{products.map((product, i) => (
				<div key={i} className={styles.nav.item()}>
					<button
						onClick={() => setOpen(!isOpen)}
						aria-expanded={isOpen ? true : false}
						className={styles.nav.item.titlerow()}
					>
						{product.title && (
							<Title.H5
								className={styles.nav.item.titlerow.title()}
								mod="inherit"
							>
								{product.title}
							</Title.H5>
						)}
						<span className={styles.nav.item.titlerow.arrow()}>
							<Icon icon="chevron_right" />
						</span>
					</button>
					{product.items?.length > 0 && (
						<AnimateHeight height={isOpen ? 'auto' : 0}>
							<div className={styles.nav.item.links()}>
								{product.items.map(
									(item, i) =>
										item?.link.url && (
											<div key={i} className={styles.nav.item.links.item()}>
												<Link to={item.link.url} onClick={onClose}>
													{item.link.description || item.link.title}
												</Link>
											</div>
										)
								)}
							</div>
						</AnimateHeight>
					)}
				</div>
			))}
		</div>
	)
}
