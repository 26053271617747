import React, { useEffect, useRef } from 'react'
import { checkIsExternal, checkIsFile, scrollToHash } from './Link'

import { useRouter } from 'next/router'

const click = (router, e) => {
	if (
		e.defaultPrevented ||
		e.button !== 0 ||
		e.altKey ||
		e.metaKey ||
		e.ctrlKey ||
		e.shiftKey ||
		e.currentTarget.target === '_blank' ||
		checkIsExternal(e.currentTarget.hostname) ||
		e.target.protocol === 'mailto:'
	)
		return
	e.preventDefault()
	const href = e.currentTarget.getAttribute('href')

	if (href.substring(0, 1) === '#') {
		scrollToHash(href)
	} else {
		router.push(href)
	}
}

const removeQueryParams = url =>
	url.indexOf('?') > 0 ? url.substring(0, url.indexOf('?')) : url

const cleanWysiwyg = text => {
	if (!text) return null
	return text
		.replace(/(<table[\w\W]+?\/table>)/g, '<div class="wysiwyg-table">$1</div>')
		.replace(/\/storage\/website\/([^"']+?.pdf)/g, '\/doc\/$1')
}

export const Trusted: React.FC<{
	className?: string
	children: string
}> = ({children, ...props}) => {
	const router = useRouter()
	const dom = useRef<HTMLDivElement>()

	useEffect(() => {
		const clear = []
		const listener = click.bind(router)

		const links: NodeListOf<HTMLAnchorElement> =
			dom?.current?.querySelectorAll('a')

		if (links?.length) {
			links.forEach(link => {
				const href = link.getAttribute('href')
				if (!href) return
				if (href.startsWith('#info:')) return
				const hrefWithoutQuery = removeQueryParams(href)

				if (checkIsFile(hrefWithoutQuery) || checkIsExternal(href)) {
					link.setAttribute('target', '_blank')
					link.setAttribute('rel', 'external nofollow noopener')
				}
				link.addEventListener('click', e => click(router, e))
				clear.push(() => link.removeEventListener('click', listener))
			})
		}

		return () => {
			clear.forEach(f => f())
		}
	})

	return (
		<div
			ref={dom}
			{...props}
			dangerouslySetInnerHTML={{
				__html: cleanWysiwyg(Array.isArray(children) ? children[0] : children)
			}}
		/>
	)
}
