import {createDefinition, stepsFilter} from 'calculators/calculator.state'

import {CMSVerwarmingChannel} from 'cms-types'
import assertNever from 'assert-never'

export const nieuweVerwarmingsInstallaties = [
	'bodemwarmte_warmtepomp',
	'grondwater_warmtepomp',
	'lucht_warmtepomp',
	'hybride',
	'pelletketel',
	'condensatieketel',
	'hoogrendementsketel'
] as const
export type NieuweVerwarmingsInstallatie =
	typeof verwarmingsInstallaties[number]

export const verwarmingsInstallaties = [
	'oude_verwarmingsinstallatie',
	...nieuweVerwarmingsInstallaties
] as const

export type VerwarmingsInstallatieType = typeof verwarmingsInstallaties[number]

export type VerwarmingState = {
	product: 'A' | 'C'
	verwarmingsinstallatie: VerwarmingsInstallatieType
}

export const verwarmingConfigureSteps = ['installatie'] as const
export type VerwarmingConfigureStepKey = typeof verwarmingConfigureSteps[number]

export type UpdateVerwarmingStateAction = {
	type: 'set_installatie'
	installatie: VerwarmingsInstallatieType
}

const onChange = (
	state: Partial<VerwarmingState>,
	action: UpdateVerwarmingStateAction
): Partial<VerwarmingState> => {
	switch (action.type) {
		case 'set_installatie':
			return {...state, verwarmingsinstallatie: action.installatie}
		default:
			assertNever(action.type)
	}
}

const getValidSteps = (): readonly VerwarmingConfigureStepKey[] => {
	return verwarmingConfigureSteps.filter(step => {
		switch (step) {
			case 'installatie':
				return true
			default:
				assertNever(step)
		}
	})
}

const getSelectableSteps = (
	state: Partial<VerwarmingState>
): readonly VerwarmingConfigureStepKey[] => {
	return stepsFilter(getValidSteps(), step => {
		switch (step) {
			case 'installatie':
				return true
			default:
				assertNever(step)
		}
	})
}

const defaultState = (
	product: 'A' | 'C',
	config: CMSVerwarmingChannel
): Partial<VerwarmingState> => {
	const verwarmingsinstallatie =
		config[`${product}_default_verwarmingsinstallatie`]

	return {
		product,
		verwarmingsinstallatie
	}
}

const isComplete = (
	state: Partial<VerwarmingState>
): state is VerwarmingState => {
	const steps = getSelectableSteps(state)
	const lastStep = steps[steps.length - 1]
	return lastStep === 'installatie' && !!state.verwarmingsinstallatie
}

export const verwarmingDef = createDefinition({
	product: 'verwarming',
	steps: verwarmingConfigureSteps,
	onChange,
	getValidSteps,
	getSelectableSteps,
	defaultState,
	isComplete
})
