import React from 'react'
import css from './Container.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Container: React.FC<{
	className?: string
	mod?: 'small'
}> = ({className, mod, children}) => (
	<div className={styles.container.with(className).mod(mod)()}>{children}</div>
)
