import React, {useEffect, useState} from 'react'

import {CMSWebsiteChannel} from 'cms-types'
import {Link} from 'layout/Link'
import css from './AdminTools.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useRouter} from 'next/router'

const styles = fromModule(css)

export const useIsAdmin = app_env => {
	const [isAdmin, setIsAdmin] = useState(false)

	useEffect(() => {
		if (app_env !== 'production') {
			setIsAdmin(true)
			return
		}

		if (!localStorage.admin_enabled) return
		if (!localStorage.admin_refreshed) return

		const validUntil =
			parseInt(localStorage.admin_refreshed, 10) + 1000 * 60 * 60 * 24 * 14
		if (Date.now() > validUntil) return

		setIsAdmin(true)
	}, [])

	return isAdmin
}

export const AdminTools: React.FC<{node: CMSWebsiteChannel}> = ({node}) => {
	const {app_env} = useApp()
	const isAdmin = useIsAdmin(app_env)
	const router = useRouter()

	if (!isAdmin) return null
	if (!node) return null
	if (router.isPreview) return null

	const editUrl =
		node?.id && node?.language && node?.project_key
			? `${process.env.NEXT_PUBLIC_ADMIN_URL}/project/${node.project_key}/${node.language}/entry/${node.id}`
			: process.env.NEXT_PUBLIC_ADMIN_URL

	return (
		<ul className={styles.tools()}>
			<li className={styles.tools.item()}>
				<Link
					to={editUrl}
					target="_blank"
					rel="external nofollow noopener"
					className={styles.tools.item.button()}
				>
					Edit
				</Link>
			</li>
			{app_env === 'development' && (
				<li className={styles.tools.item()}>
					<Link
						to={`/api/data${node.url}`}
						target="_blank"
						rel="external nofollow noopener"
						className={styles.tools.item.button()}
					>
						JSON
					</Link>
				</li>
			)}
		</ul>
	)
}
