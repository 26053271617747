import * as icons from './icons'

import React, {HTMLProps} from 'react'

import {Variant} from 'util/styler'
import css from './icon.module.scss'
import {fromModule} from 'util/styler/Styler'

export type IconKey = keyof typeof icons

const styles = fromModule(css)

export const Icon: React.FC<
	{
		icon: IconKey
		inline?: boolean
		mod?: Variant<'maxwidth'>
	} & HTMLProps<HTMLDivElement>
> = ({icon, inline, mod, ...rest}) => {
	if (!icons[icon]) {
		console.warn(`Undefined icon key: ${icon}`)
		return null
	}

	const Component = icons[icon] as any
	return (
		<span
			{...rest}
			className={styles.icon.mergeProps(rest).mod({inline}).mod(mod)()}
			data-icon={icon}
		>
			<Component />
		</span>
	)
}
