import {EnergieLabel, cleanLabel} from './labels'
import {
	styleFormatsAlign,
	styleFormatsButtons,
	styleFormatsHeaders,
	styleFormatsImage
} from 'layout/wysiwyg.config'

import {Component} from '@theparkinglot/cms-config'

export const generalCalculatorFields: Component[] = []

export const generalProductFields: Component[] = [
	{
		key: 'product_link',
		label: 'Product link',
		type: 'Link',
		options: {
			type: 'entry',
			multiple: false,
			select_project: 'website',
			channels: ['product_detail']
		}
	}
]

export const defaultLabelComp = (labels: EnergieLabel[]): Component => {
	return {
		key: 'default_label',
		label: 'Default label',
		type: 'Dropdown',
		options: {
			options: labels.reduce((acc, v) => ({...acc, [v]: cleanLabel(v)}), {}),
			as_string: true,
			width: 0.5
		}
	}
}

export const createIsoComps = (keys: readonly string[]): Component[] => {
	return keys.flatMap<Component>(key => [
		{
			key: `iso_label_${key}`,
			label: `Label ${key}`,
			type: 'Text',
			options: {
				width: 0.3
			}
		},
		{
			key: `iso_short_${key}`,
			label: `Short ${key}`,
			type: 'Text',
			options: {
				width: 0.2
			}
		},
		{
			key: `iso_img_${key}`,
			label: `Image ${key}`,
			type: 'Link',
			options: {
				type: 'image',
				width: 0.5
			}
		}
	])
}

export const createStepsForCMSChannel = (
	record: Record<string, string>
): Component[] => {
	return Object.keys(record).flatMap(key => {
		const label = record[key]
		return [
			...createStepForCMSChannel(key, label, []),
			{
				key: `${key}_info`,
				label: `Extra info popup`,
				type: 'Wysiwyg',
				options: {
					toolbar:
						'undo redo | formatselect | bold italic | alignleft alignright bullist numlist table blockquote | link unlink | image code'
				}
			},
			{
				key: `${key}_info_mobile`,
				label: `Extra info popup for mobile`,
				type: 'Wysiwyg',
				options: {
					toolbar:
						'undo redo | formatselect | bold italic | bullist numlist | link unlink | image code'
				}
			}
		]
	})
}

export function createPopupSteps<K extends string>(
	labels: Record<K, string>
): Component[] {
	return Object.keys(labels).flatMap(step => {
		const label = labels[step]
		return [
			{
				label: label,
				type: 'Title'
			},
			{
				key: `${step}_info`,
				label: `Extra info popup`,
				type: 'Wysiwyg',
				options: {
					toolbar:
						'undo redo | styleselect | bold italic subscript superscript | removeformat | bullist numlist blockquote | link unlink image | pastetext code',
					style_formats: [
						styleFormatsHeaders,
						styleFormatsAlign,
						styleFormatsButtons,
						styleFormatsImage
					] as any
				}
			},
			{
				key: `${step}_info_mobile`,
				label: `Extra info popup for mobile`,
				type: 'Wysiwyg',
				options: {
					toolbar:
						'undo redo | styleselect | bold italic subscript superscript | removeformat | bullist numlist blockquote | link unlink image | pastetext code',
					style_formats: [
						styleFormatsHeaders,
						styleFormatsAlign,
						styleFormatsButtons,
						styleFormatsImage
					] as any
				}
			}
		]
	})
}

export function createProductSteps<K extends string>(
	product: 'A' | 'B' | 'C',
	labels: Partial<Record<K, string>>,
	steps: (K | [K, Component | Component[]])[]
): Component[] {
	return steps.flatMap(data => {
		let key: string = null
		let components: Component[] = []
		if (!Array.isArray(data)) {
			key = data
		} else {
			key = data[0]
			if (Array.isArray(data[1])) {
				components = data[1]
			} else {
				components = [data[1]]
			}
		}

		return createStepForCMSChannel(
			`${product}_${key}`,
			`${labels[key]} [${product}]`,
			components.map(comp => {
				return {
					...comp,
					key: `${product}_${(comp as any).key}`
				}
			})
		)
	})
}

export function createStepForCMSChannel<K extends string>(
	key: K,
	label: string,
	components?: Component[]
): Component[] {
	return [
		{
			label: label,
			type: 'Title'
		},
		{
			key: `${key}_question`,
			label: 'Vraagstelling',
			type: 'Text'
		},
		{
			key: `${key}_instructions`,
			label: `Instructies`,
			type: 'Wysiwyg',
			options: {
				toolbar:
					'undo redo | formatselect | bold italic | bullist numlist table blockquote | link unlink | image code'
			}
		},
		...(components || [])
	]
}

export const generalCalculatorStepFields = (
	prefix: string,
	label?: string
): Component[] => [
	{
		key: `${prefix}_question`,
		label: label ? `Vraagstelling ${label}` : 'Vraagstelling',
		type: 'Text'
	},
	{
		key: `${prefix}_info`,
		label: label ? `Extra info ${label}` : 'Extra info',
		type: 'Wysiwyg',
		options: {
			toolbar:
				'undo redo | styleselect | bold italic subscript superscript | removeformat | bullist numlist blockquote | link unlink image | pastetext code',
			style_formats: [
				styleFormatsHeaders,
				styleFormatsAlign,
				styleFormatsButtons,
				styleFormatsImage
			] as any
		}
	},
	{
		key: `${prefix}_info_mobile`,
		label: label ? `Extra info ${label} for mobile` : 'Extra info for mobile',
		type: 'Wysiwyg',
		options: {
			toolbar:
				'undo redo | styleselect | bold italic subscript superscript | removeformat | bullist numlist blockquote | link unlink image | pastetext code',
			style_formats: [
				styleFormatsHeaders,
				styleFormatsAlign,
				styleFormatsButtons,
				styleFormatsImage
			] as any
		}
	}
]

export const generalCalculatorResultFields: Component[] = [
	{
		key: 'resultaat_title',
		label: 'Resultaat titel',
		type: 'Text'
	},
	{
		key: 'resultaat_label',
		label: 'Resultaat label',
		type: 'Text'
	},
	{
		key: 'resultaat_info_geen_profiel',
		label: 'Resultaat zonder persoonlijk profiel: info',
		type: 'Wysiwyg',
		options: {
			toolbar: 'b'
		}
	},
	{
		key: 'resultaat_info_profiel',
		label: 'Resultaat met persoonlijk profiel: info',
		type: 'Wysiwyg',
		options: {
			toolbar: 'b'
		}
	},
	{
		key: 'resultaat_info_collection',
		label: 'Resultaat info collection',
		type: 'FieldCollection',
		options: {
			initialItem: false,
			components: [
				{
					key: 'resultaat_info',
					label: 'Resultaat info',
					type: 'Wysiwyg',
					options: {
						toolbar: 'b'
					}
				}
			]
		}
	}
]

export const generalCalculatorCompareFields: Component[] = [
	{
		type: 'Text',
		key: `vergelijken_question`,
		label: `Vergelijking: vraagstelling`
	},
	{
		type: 'Text',
		key: `vergelijken_question_product`,
		label: `Vergelijking met productprofiel: vraagstelling`
	},
	{
		key: `vergelijken_info_product_geen_profiel`,
		label: `Vergelijking met productprofiel en zonder persoonlijk profiel: info`,
		type: 'Wysiwyg',
		options: {toolbar: 'b'}
	},
	{
		key: `vergelijken_info_product`,
		label: `Vergelijking met productprofiel: info`,
		type: 'Wysiwyg',
		options: {toolbar: 'b'}
	},
	{
		key: 'vergelijken_info_collection',
		label: 'Vergelijking info collection',
		type: 'FieldCollection',
		options: {
			initialItem: false,
			components: [
				{
					key: 'vergelijken_info',
					label: 'Vergelijking info',
					type: 'Wysiwyg',
					options: {
						toolbar: 'b'
					}
				}
			]
		}
	}
]

export const generalCalculatorSavingsFields: Component[] = [
	{
		type: 'Text',
		key: `besparen_question`,
		label: `Besparing: vraagstelling`
	},
	{
		key: 'besparen_info_geen_product',
		label: 'Besparing zonder productprofiel: info',
		type: 'Wysiwyg',
		options: {
			toolbar: 'b'
		}
	},
	{
		key: 'besparen_info_product',
		label: 'Besparing met productprofiel: info',
		type: 'Wysiwyg',
		options: {
			toolbar: 'b'
		}
	}
]
