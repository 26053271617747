import {Channel, Component} from '@theparkinglot/cms-config'

import {generalCalculatorStepFields} from './calculator.cms'
import {verwarmingsInstallaties} from './verwarming/verwarming.state'

export const woningTypes = [
	'studio_of_klein_appartement',
	'gemiddeld_appartement_of_klein_rijhuis',
	'rijhuis_of_groot_appartement',
	'groot_rijhuis_of_alleenstaande_woning',
	'villa',
	'kasteel'
] as const
export type WoningType = typeof woningTypes[number]

export const verwarmingsTypes = [
	'stookolie',
	'aardgas',
	'propaan_butaan',
	'pellets',
	'elektriciteit'
] as const
export type VerwarmingsType = typeof verwarmingsTypes[number]

const verwarmingsTypeUnits = {
	stookolie: 'liter',
	aardgas: 'm³',
	propaan_butaan: 'liter',
	pellets: 'kg',
	elektriciteit: 'kWh'
} as const

export const isolatiegraadTypes = [
	'niet',
	'slecht',
	'redelijk',
	'goed',
	'heel_goed'
] as const
export type IsolatiegraadType = typeof isolatiegraadTypes[number]

export const wagenGroeneStroomTypes = ['altijd', 'soms', 'nooit'] as const
export type WagenGroeneStroomType = typeof wagenGroeneStroomTypes[number]

export const wagenBrandstofTypes = [
	'diesel',
	'benzine',
	'lpg',
	'cng',
	'waterstof'
] as const
export type WagenBrandstofType = typeof wagenBrandstofTypes[number]

const wagenBrandstofTypeUnits = {
	diesel: 'liter',
	benzine: 'liter',
	lpg: 'liter',
	cng: 'kg',
	waterstof: 'kg'
} as const

export const channel: Channel = {
	key: 'profile',
	label: 'Profiel',
	options: {
		auto_published: true
	},
	components: [
		{
			type: 'TabCollection',
			options: {
				tabs: [
					{
						label: 'Defaults',
						components: [
							{
								key: 'default_gezinsleden',
								label: 'Default gezinsleden',
								type: 'Text',
								options: {
									ruleset: 'number',
									width: 0.5
								}
							},
							{
								key: 'default_woningtype',
								label: 'Default woningtype',
								type: 'Dropdown',
								options: {
									options: woningTypes.reduce(
										(acc, key) => ({...acc, [key]: key.split('_').join(' ')}),
										{}
									),
									as_string: true,
									width: 0.5
								}
							},
							{
								key: 'default_isolatiegraad',
								label: 'Default isolatiegraad',
								type: 'Dropdown',
								options: {
									options: isolatiegraadTypes.reduce(
										(acc, key) => ({...acc, [key]: key}),
										{}
									),
									as_string: true,
									width: 0.5
								}
							},
							{
								key: 'default_kamertemperatuur',
								label: 'Default kamertemperatuur',
								type: 'Text',
								options: {
									help: 'graden Celsius',
									ruleset: 'number',
									width: 0.5
								}
							},
							{
								key: 'default_verwarmingsinstallatie',
								label: 'Default verwarmingsinstallatie',
								type: 'Dropdown',
								options: {
									options: verwarmingsInstallaties.reduce(
										(acc, key) => ({...acc, [key]: key.replace('_', ' ')}),
										{}
									),
									as_string: true,
									width: 0.5
								}
							},
							{
								key: 'default_verwarmingstype',
								label: 'Default verwarmingstype',
								type: 'Dropdown',
								options: {
									options: verwarmingsTypes.reduce(
										(acc, key) => ({...acc, [key]: key.replace('_', '/')}),
										{}
									),
									as_string: true,
									width: 0.5
								}
							},
							{
								key: 'default_groene_stroom',
								label: 'Default groene stroom',
								type: 'Checkbox',
								options: {
									width: 0.5
								}
							},
							{
								key: 'default_stroomprijs',
								label: 'Default stroomprijs',
								type: 'Text',
								options: {
									help: 'euro/kWh',
									ruleset: 'number',
									width: 0.5
								}
							},
							{
								key: 'default_waterprijs',
								label: 'Default waterprijs',
								type: 'Text',
								options: {
									help: 'euro/m³',
									ruleset: 'number',
									width: 0.5
								}
							},
							{
								key: 'default_wagen_groene_stroom',
								label: 'Default wagen groene stroom',
								type: 'Dropdown',
								options: {
									options: wagenGroeneStroomTypes.reduce(
										(acc, key) => ({...acc, [key]: key.replace('_', '/')}),
										{}
									),
									as_string: true,
									width: 0.5
								}
							},
							{
								key: 'default_wagen_brandstof',
								label: 'Default brandstof',
								type: 'Dropdown',
								options: {
									options: wagenBrandstofTypes.reduce(
										(acc, key) => ({...acc, [key]: key.replace('_', '/')}),
										{}
									),
									as_string: true,
									width: 0.5
								}
							},
							{
								type: 'Title',
								label: 'Default prijzen verwarming'
							},
							...verwarmingsTypes.map<Component>(type => ({
								key: `default_prijs_${type}`,
								label: `Default ${type.replace('_', '/')} prijs`,
								type: 'Text',
								options: {
									help: `euro/${verwarmingsTypeUnits[type]}`,
									ruleset: 'number',
									width: 0.5
								}
							})),
							{
								type: 'Title',
								label: 'Default prijzen wagen'
							},
							{
								key: 'default_wagen_stroomprijs',
								label: 'Default stroomprijs',
								type: 'Text',
								options: {
									help: 'euro/kWh',
									ruleset: 'number',
									width: 0.5
								}
							},
							...wagenBrandstofTypes.map<Component>(type => ({
								key: `default_brandstofprijs_${type}`,
								label: `Default ${type} prijs`,
								type: 'Text',
								options: {
									help: `euro/${wagenBrandstofTypeUnits[type]}`,
									ruleset: 'number',
									width: 0.5
								}
							}))
						]
					},
					{
						label: 'Config',
						components: [
							{
								key: 'emissie_grijze_stroom',
								label: 'Default emissie grijze stroom',
								type: 'Text',
								options: {
									help: 'kg CO2*kWh',
									ruleset: 'number',
									width: 0.5
								}
							},
							{
								key: 'factor_groene_stroom',
								label:
									'Emissiefactor groene stroom (verhouding uitstoot tov. grijze stroom)',
								type: 'Text',
								options: {
									ruleset: 'number',
									width: 0.5
								}
							},
							{
								key: 'emissie_aardgas',
								label: 'Default emissie aardgas',
								type: 'Text',
								options: {
									help: 'kg CO2*kWh',
									ruleset: 'number',
									width: 0.5
								}
							},
							{
								type: 'Title',
								label: 'Verwarming config'
							},
							...verwarmingsTypes.flatMap<Component>(type => [
								{
									key: `${type}_co2`,
									label: `${type.replace('_', '/')} CO2`,
									type: 'Text',
									options: {
										help: 'kg CO2/kWh',
										ruleset: 'number',
										width: 0.5
									}
								},
								{
									key: `${type}_kwh`,
									label: `${type.replace('_', '/')} kWh/e`,
									type: 'Text',
									options: {
										help: `kWh/${verwarmingsTypeUnits[type]}`,
										ruleset: 'number',
										width: 0.5
									}
								}
							]),
							{
								type: 'Title',
								label: 'Verwarmingsinstallatie config'
							},
							...verwarmingsInstallaties.flatMap<Component>(inst => [
								{
									key: `${inst}_cop`,
									label: `${inst
										.split('_')
										.join(' ')} coefficient of performance`,
									type: 'Text',
									options: {
										ruleset: 'number',
										width: 0.5
									}
								}
							])
						]
					},
					{
						label: 'Sidebar labels',
						components: [
							...generalCalculatorStepFields('gezinsleden', 'gezinsleden'),
							...generalCalculatorStepFields('woningtype', 'woningtype'),
							...generalCalculatorStepFields('isolatiegraad', 'isolatiegraad'),
							...generalCalculatorStepFields(
								'kamertemperatuur',
								'kamertemperatuur'
							),
							...generalCalculatorStepFields('groene_stroom', 'groene stroom'),
							...generalCalculatorStepFields('stroomprijs', 'stroomprijs'),
							...generalCalculatorStepFields(
								'verwarmingsinstallatie',
								'verwarmingsinstallatie'
							),
							...generalCalculatorStepFields(
								'verwarmingstype',
								'verwarmingstype'
							),
							...generalCalculatorStepFields(
								'verwarmingsprijs',
								'verwarmingsprijs'
							),
							...generalCalculatorStepFields('waterprijs', 'waterprijs'),
							...generalCalculatorStepFields('aardgasprijs', 'aardgasprijs'),
							...generalCalculatorStepFields('jaarverbruik', 'jaarverbruik'),
							...generalCalculatorStepFields(
								'stookolieprijs',
								'stookolieprijs'
							),
							...generalCalculatorStepFields(
								'propaan_butaanprijs',
								'propaan/butaanprijs'
							),
							...generalCalculatorStepFields(
								'wagen_groene_stroom',
								'wagen groene stroom'
							),
							...generalCalculatorStepFields(
								'wagen_stroomprijs',
								'wagen stroomprijs'
							),
							...generalCalculatorStepFields(
								'wagen_brandstof',
								'wagen brandstof'
							),
							...generalCalculatorStepFields(
								'wagen_brandstofprijs',
								'wagen brandstofprijs'
							)
						]
					}
				]
			}
		}
	]
}
