import {Icon, IconKey} from 'layout/Icon'

import {Button} from 'layout/Button'
import {CMSAccountChannelExtra} from 'channels/account/account.data'
import {CMSWysiwyg} from 'cms-types'
import {DakState} from 'calculators/dak/dak.state'
import {DiepvriezerState} from 'calculators/diepvriezer/diepvriezer.state'
import {DroogkastState} from 'calculators/droogkast/droogkast.state'
import {KoelkastState} from 'calculators/koelkast/koelkast.state'
import {KookplatenState} from 'calculators/kookplaten/kookplaten.state'
import {LinkStyled} from 'layout/LinkStyled'
import {MurenState} from 'calculators/muren/muren.state'
import NextLink from 'next/link'
import {OvenState} from 'calculators/oven/oven.state'
import {PropsWithChildren} from 'react'
import {Title} from 'layout/Title'
import {VaatwasserState} from 'calculators/vaatwasser/vaatwasser.state'
import {VenstersState} from 'calculators/vensters/vensters.state'
import {VerlichtingState} from 'calculators/verlichting/verlichting.state'
import {VerwarmingState} from 'calculators/verwarming/verwarming.state'
import {WagenState} from 'calculators/wagen/wagen.state'
import {WarmWaterState} from 'calculators/warmwater/warmwater.state'
import {WasmachineState} from 'calculators/wasmachine/wasmachine.state'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './ProductProfiles.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'
import {scrollToHash} from 'layout/Link'
import {useApp} from 'AppContext'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const importOptions = {ssr: false}

const {
	DakProfile,
	DroogkastProfile,
	DiepvriezerProfile,
	KoelkastProfile,
	KookplatenProfile,
	MurenProfile,
	OvenProfile,
	VaatwasserProfile,
	VenstersProfile,
	VerlichtingProfile,
	VerwarmingProfile,
	WagenProfile,
	WarmWaterProfile,
	WasmachineProfile
} = {
	DakProfile: dynamic(
		() => import('account/products/DakProfile'),
		importOptions
	),
	DiepvriezerProfile: dynamic(
		() => import('account/products/DiepvriezerProfile'),
		importOptions
	),
	DroogkastProfile: dynamic(
		() => import('account/products/DroogkastProfile'),
		importOptions
	),
	KoelkastProfile: dynamic(
		() => import('account/products/KoelkastProfile'),
		importOptions
	),
	KookplatenProfile: dynamic(
		() => import('account/products/KookplatenProfile'),
		importOptions
	),
	MurenProfile: dynamic(
		() => import('account/products/MurenProfile'),
		importOptions
	),
	OvenProfile: dynamic(
		() => import('account/products/OvenProfile'),
		importOptions
	),
	VaatwasserProfile: dynamic(
		() => import('account/products/VaatwasserProfile'),
		importOptions
	),
	VenstersProfile: dynamic(
		() => import('account/products/VenstersProfile'),
		importOptions
	),
	VerlichtingProfile: dynamic(
		() => import('account/products/VerlichtingProfile'),
		importOptions
	),
	VerwarmingProfile: dynamic(
		() => import('account/products/VerwarmingProfile'),
		importOptions
	),
	WagenProfile: dynamic(
		() => import('account/products/WagenProfile'),
		importOptions
	),
	WarmWaterProfile: dynamic(
		() => import('account/products/WarmWaterProfile'),
		importOptions
	),
	WasmachineProfile: dynamic(
		() => import('account/products/WasmachineProfile'),
		importOptions
	)
}

const styles = fromModule(css)

export const productCategories = ['A', 'B', 'C']
export type ProductCategory = typeof productCategories[number]

export const productKeys = [
	'dak',
	'muren',
	'vensters',
	'koelkast',
	'diepvriezer',
	'wasmachine',
	'oven',
	'droogkast',
	'vaatwasser',
	'kookplaten',
	'wagen',
	'warmwater',
	'verlichting',
	'verwarming'
] as const
export type ProductKey = typeof productKeys[number]

export type ProductStates = {
	dak?: Partial<DakState>
	muren?: Partial<MurenState>
	vensters?: Partial<VenstersState>
	koelkast?: Partial<KoelkastState>
	diepvriezer?: Partial<DiepvriezerState>
	wasmachine?: Partial<WasmachineState>
	oven?: Partial<OvenState>
	droogkast?: Partial<DroogkastState>
	vaatwasser?: Partial<VaatwasserState>
	kookplaten?: Partial<KookplatenState>
	wagen?: Partial<WagenState>
	warmwater?: Partial<WarmWaterState>
	verlichting?: Partial<VerlichtingState>
	verwarming?: Partial<VerwarmingState>
}

export type ABCProducts = Record<ProductCategory, ProductStates>

type ProductObject = {key: {title: string; icon: IconKey; icon_solid: IconKey}}

const productViews = {
	dak: DakProfile,
	muren: MurenProfile,
	vensters: VenstersProfile,
	koelkast: KoelkastProfile,
	diepvriezer: DiepvriezerProfile,
	wasmachine: WasmachineProfile,
	oven: OvenProfile,
	droogkast: DroogkastProfile,
	vaatwasser: VaatwasserProfile,
	kookplaten: KookplatenProfile,
	wagen: WagenProfile,
	warmwater: WarmWaterProfile,
	verwarming: VerwarmingProfile,
	verlichting: VerlichtingProfile
}

export const ProductProfiles: React.FC<{
	products: CMSAccountChannelExtra['products']
	description?: CMSWysiwyg
}> = ({products, description}) => {
	const app = useApp()
	const t = useTranslation()

	const router = useRouter()
	const product = router.query.product as ProductKey

	const ProductView: any = productViews[product]

	const getProductObjects = (productKeys: ProductKey[]) =>
		productKeys.reduce(
			(acc, prod) =>
				acc.concat({
					[prod]: {
						title: products[prod]?.title || '',
						icon: products[prod]?.product_link?.icon,
						icon_solid: products[prod]?.product_link?.icon_solid
					}
				}),
			[]
		)

	return (
		<div className={styles.productprofiles()}>
			<div className={styles.productprofiles.row()}>
				<aside
					id="productprofiles_sidebar"
					className={styles.productprofiles.row.sidebar()}
				>
					<div className={styles.productprofiles.row.sidebar.categories()}>
						<ProductProfilesCategory
							category={t.profile.product_profiles.categories.home}
							products={getProductObjects([
								'dak',
								'muren',
								'vensters',
								'verwarming',
								'warmwater',
								'verlichting'
							])}
						/>
						<ProductProfilesCategory
							category={t.profile.product_profiles.categories.electro}
							products={getProductObjects([
								'koelkast',
								'diepvriezer',
								'wasmachine',
								'droogkast',
								'vaatwasser',
								'kookplaten',
								'oven'
							])}
						/>
						<ProductProfilesCategory
							category={t.profile.product_profiles.categories.movements}
							products={getProductObjects(['wagen'])}
						/>
					</div>
				</aside>
				<div
					id="productprofiles_content"
					className={styles.productprofiles.row.content()}
				>
					{description && !ProductView && <Wysiwyg>{description}</Wysiwyg>}
					{app.loaded && ProductView && (
						<>
							<ProductProfilesIntro link={products[product].product_link} />
							<ProductView config={products[product]} />
						</>
					)}
				</div>
			</div>
			<a
				onClick={() => scrollToHash('#productprofiles_sidebar')}
				className={styles.productprofiles.scrollup()}
			>
				<Icon icon="chevron_up_regular" />
			</a>
		</div>
	)
}

const ProductProfilesCategory: React.FC<{
	category: string
	products: any[]
}> = ({category, products}) => {
	const router = useRouter()
	const product = router.query.product as ProductKey

	return (
		<div className={styles.category()}>
			<h5 className={styles.category.title()}>{category}</h5>
			<div className={styles.category.products()}>
				{products.map((obj, i) => {
					const [key, {title, icon, icon_solid}] = Object.entries(
						obj as ProductObject
					)[0]
					const active = product === key
					return (
						<NextLink
							key={i}
							href={{
								query: {
									tab: router.query.tab,
									product: key,
									slug: router.query.slug
								}
							}}
							shallow
						>
							<a
								onClick={() => scrollToHash('#productprofiles_content')}
								className={styles.category.products.item.is({
									active: active
								})()}
							>
								<span className={styles.category.products.item.icon()}>
									<Icon icon={active ? icon_solid : icon} mod="maxwidth" />
								</span>
								<p>{title}</p>
							</a>
						</NextLink>
					)
				})}
			</div>
		</div>
	)
}

export const ProductProfilesIntro: React.FC<{
	link: {
		title: string
		url: string
		icon_solid: IconKey
	}
}> = ({link}) => {
	const t = useTranslation()
	return (
		<div className={styles.intro()}>
			{Object.keys(link).length !== 0 && (
				<div className={styles.intro.top()}>
					<div className={styles.intro.top.product()}>
						<span className={styles.intro.top.product.icon()}>
							<Icon icon={link.icon_solid} />
						</span>
						<span>{link.title}</span>
					</div>
					<LinkStyled
						to={link.url}
						iconafter="chevron_right"
						className={styles.intro.top.link()}
						mod={['regular', 'small']}
					>
						{t.profile.product_profiles.link}
					</LinkStyled>
				</div>
			)}
			<Title.H4 as="h3">{t.profile.product_profiles.title}</Title.H4>
		</div>
	)
}

interface ProductProfilesFormProps<Prod extends ProductKey> {
	product: Prod
	state: Partial<ProductStates[Prod]>
}

export function ProductProfilesForm<Prod extends ProductKey>({
	product,
	state,
	children
}: PropsWithChildren<ProductProfilesFormProps<Prod>>) {
	const app = useApp()
	const t = useTranslation()
	const current = app.user.products_A?.[product]
	const isUnchanged = JSON.stringify(current) === JSON.stringify(state)

	return (
		<form
			onSubmit={e => {
				e.preventDefault()
				app.updateProduct('A', {[product]: state})
			}}
		>
			{children}
			<Button
				as="button"
				disabled={isUnchanged}
				className={styles.form.button()}
			>
				{t.profile.product_profiles.save}
			</Button>
		</form>
	)
}

export const ProductProfilesField: React.FC<{
	label: string
}> = ({label, children}) => {
	return (
		<div style={{padding: '20px 0'}}>
			<label style={{display: 'block', marginBottom: '5px'}}>{label}</label>
			<div>{children}</div>
		</div>
	)
}
