import NextImage, {ImageProps} from 'next/image'

import {CMSImage} from 'cms-types'
import css from './Image.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useState} from 'react'

const styles = fromModule(css)

declare type ImgElementStyle = NonNullable<
	JSX.IntrinsicElements['img']['style']
>

export const Image: React.FC<
	CMSImage &
		ImageProps & {
			sizes: string
			objectFit?: 'cover' | ImgElementStyle['objectFit']
			objectPosition?: ImgElementStyle['objectPosition']
			className?: string
		}
> = ({
	layout,
	sizes,
	priority = false,
	objectFit,
	objectPosition,
	className,
	...image
}) => {
	const [imgLoaded, setImgLoaded] = useState<boolean>(false)
	if (!image?.src) return null
	const srcPost = image?.size ? `?size=${image.size}` : ''

	return (
		<div
			className={styles.image
				.is({loaded: imgLoaded})
				.mod(layout)
				.with(className)()}
		>
			<NextImage
				sizes={sizes}
				priority={priority}
				{...switchProps(image, layout, objectFit, objectPosition)}
				src={image.src + srcPost}
				alt={image?.alt || image?.title}
				onLoadingComplete={() => setImgLoaded(true)}
			/>
		</div>
	)
}

export const getImageRatio = (image: CMSImage, width: number) => {
	if (image.height > image.width) return (image.height / image.width) * width
	return width / (image.width / image.height)
}

const switchProps = (
	image,
	layout?: 'intrinsic' | 'fixed' | 'responsive' | 'fill' | 'raw',
	objectFit?: 'cover' | ImgElementStyle['objectFit'],
	objectPosition?: ImgElementStyle['objectPosition']
) => {
	if (layout === 'responsive' && image?.width && !image?.height)
		image.height = getImageRatio(image, image?.width)

	const focus = image?.focus
		? `${image.focus?.x * 100}% ${image.focus?.y * 100}%`
		: 'center center'

	switch (layout) {
		case 'responsive':
			return {layout: 'responsive', width: image?.width, height: image?.height}

		case 'fill':
			return {
				layout: 'fill',
				objectFit: objectFit || 'cover',
				objectPosition: objectPosition || focus
			}

		default:
			return {...image}
	}
}
