import * as langs from './index'

import React, {createContext, useContext} from 'react'

import {LangKey} from './locale'

const LocaleContext = createContext<LangKey>('nl')

export const LocaleContextProvider: React.FC<{
	lang: LangKey
}> = ({lang, children}) => {
	return (
		<LocaleContext.Provider value={lang}>{children}</LocaleContext.Provider>
	)
}

export const useTranslation = () => {
	const lang = useContext(LocaleContext)
	return langs[lang]
}
