import {Container} from 'layout/Container'
import React from 'react'
import {Social} from 'layout/Social'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Copyright.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export const Copyright: React.FC = () => {
	const {general} = useApp()
	const {social, copyright} = general.footer
	if ((!social || social.length === 0) && !copyright) return null

	return (
		<div className={styles.copyright()}>
			<Container>
				<div className={styles.copyright.row()}>
					<div className={styles.copyright.row.left()}>
						<Social social={social} />
					</div>
					<div className={styles.copyright.row.right()}>
						{copyright && (
							<Wysiwyg className={styles.copyright.row.right.copyright()}>
								{copyright}
							</Wysiwyg>
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}
