import {CMSProfileChannel, CMSVerwarmingChannel} from 'cms-types'
import {
	IsolatiegraadType,
	VerwarmingsType,
	WagenBrandstofType,
	WagenGroeneStroomType,
	WoningType,
	isolatiegraadTypes,
	wagenBrandstofTypes,
	wagenGroeneStroomTypes,
	woningTypes
} from './profile.channel'
import {UserState, useApp} from 'AppContext'
import {
	VerwarmingsInstallatieType,
	verwarmingsInstallaties
} from './verwarming/verwarming.state'

import {Icon} from 'layout/Icon'
import {LinkStyled} from 'layout/LinkStyled'
import assertNever from 'assert-never'
import css from './Profile.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/LocaleContext'

const importOptions = {ssr: false}

const {
	Config,
	ConfigCheckbox,
	ConfigDropdown,
	ConfigInteger,
	ConfigRange,
	CalculatorSidebar
} = {
	Config: dynamic(
		() => import('layout/calculators/Config').then(mod => mod.Config),
		importOptions
	),
	ConfigCheckbox: dynamic(
		() => import('layout/calculators/Config').then(mod => mod.ConfigCheckbox),
		importOptions
	),
	ConfigDropdown: dynamic(
		() => import('layout/calculators/Config').then(mod => mod.ConfigDropdown),
		importOptions
	),
	ConfigInteger: dynamic(
		() => import('layout/calculators/Config').then(mod => mod.ConfigInteger),
		importOptions
	),
	ConfigRange: dynamic(
		() => import('layout/calculators/Config').then(mod => mod.ConfigRange),
		importOptions
	),
	CalculatorSidebar: dynamic(
		() =>
			import('layout/calculators/Calculator').then(
				mod => mod.CalculatorSidebar
			),
		importOptions
	)
}

const styles = fromModule(css)

export const profileAttributes = [
	'gezinsleden',
	'woningtype',
	'isolatiegraad',
	'kamertemperatuur',
	'groene_stroom',
	'stroomprijs',
	'verwarmingsinstallatie',
	'verwarmingstype',
	'verwarmingsprijs',
	'waterprijs',
	'aardgasprijs',
	'jaarverbruik',
	'stookolieprijs',
	'propaan_butaanprijs',
	'wagen_groene_stroom',
	'wagen_stroomprijs',
	'wagen_brandstof',
	'wagen_brandstofprijs'
] as const
export type ProfileAttribute = typeof profileAttributes[number]

export type ProfileState = {
	personalized: boolean
	gezinsleden: number
	woningtype: WoningType
	isolatiegraad: IsolatiegraadType
	kamertemperatuur: number
	groene_stroom: boolean
	stroomprijs: number
	verwarmingsinstallatie: VerwarmingsInstallatieType
	verwarmingstype: VerwarmingsType
	verwarmingsprijs: number
	waterprijs: number
	aardgasprijs: number
	jaarverbruik: number
	stookolieprijs: number
	propaan_butaanprijs: number
	wagen_groene_stroom: WagenGroeneStroomType
	wagen_stroomprijs: number
	wagen_brandstof: WagenBrandstofType
	wagen_brandstofprijs: number
}

export const calculateJaarverbruik = (gezinsleden: number) => {
	return Math.round(Math.sqrt(gezinsleden / 2.5) * 3900)
}

export type ProfileConfig = {
	profile: CMSProfileChannel
	verwarming: CMSVerwarmingChannel
}

export const fillProfileWithDefaults = (
	partialProfile: Partial<ProfileState>,
	config: ProfileConfig
): UserState['profile'] => {
	const {profile: defaults} = config
	const gezinsleden = partialProfile.gezinsleden || defaults.default_gezinsleden
	const verwarmingsinstallatie =
		partialProfile.verwarmingsinstallatie ||
		defaults.default_verwarmingsinstallatie
	const verwarmingstype =
		partialProfile.verwarmingstype || defaults.default_verwarmingstype
	const wagen_brandstof =
		partialProfile.wagen_brandstof || defaults.default_wagen_brandstof
	const isolatiegraad =
		partialProfile.isolatiegraad || defaults.default_isolatiegraad
	const woningtype = partialProfile.woningtype || defaults.default_woningtype
	const kamertemperatuur =
		partialProfile.kamertemperatuur || defaults.default_kamertemperatuur
	const groene_stroom =
		partialProfile.groene_stroom || defaults.default_groene_stroom
	const stroomprijs = partialProfile.stroomprijs || defaults.default_stroomprijs
	const aardgasprijs =
		partialProfile.aardgasprijs ||
		Math.round((defaults.default_prijs_aardgas / 11) * 100) / 100
	const verwarmingsprijs =
		partialProfile.verwarmingsprijs ||
		(verwarmingstype === 'aardgas'
			? aardgasprijs
			: defaults[`default_prijs_${verwarmingstype}`])

	return {
		personalized: partialProfile.personalized || false,
		gezinsleden,
		woningtype,
		isolatiegraad,
		kamertemperatuur,
		groene_stroom,
		stroomprijs,
		verwarmingsinstallatie,
		verwarmingstype,
		verwarmingsprijs,
		waterprijs: partialProfile.waterprijs || defaults.default_waterprijs,
		aardgasprijs,
		jaarverbruik:
			partialProfile.jaarverbruik || calculateJaarverbruik(gezinsleden),
		stookolieprijs:
			partialProfile.stookolieprijs || defaults.default_prijs_stookolie,
		propaan_butaanprijs:
			partialProfile.propaan_butaanprijs ||
			defaults.default_prijs_propaan_butaan,
		wagen_groene_stroom:
			partialProfile.wagen_groene_stroom ||
			defaults.default_wagen_groene_stroom,
		wagen_stroomprijs:
			partialProfile.wagen_stroomprijs || defaults.default_wagen_stroomprijs,
		wagen_brandstof,
		wagen_brandstofprijs:
			partialProfile.wagen_brandstofprijs ||
			defaults[`default_brandstofprijs_${wagen_brandstof}`]
	}
}

export const useCalculatePriceFromKwh = (
	energiebron: 'elektriciteit' | 'gas' = 'elektriciteit'
) => {
	const {
		user: {profile}
	} = useApp()

	const bron = energiebron === 'elektriciteit' ? 'stroom' : 'aardgas'

	return (kwh: number | false): number | false => {
		if (kwh === false) return false

		return Math.round(kwh * profile[`${bron}prijs`] * 100) / 100
	}
}

export const useCalculatePriceFromWater = () => {
	const {
		user: {profile}
	} = useApp()

	return (water: number | false): number | false => {
		if (water === false) return false

		return Math.round((water * profile.waterprijs) / 10) / 100
	}
}

export const useCalculatePrice = () => {
	const calculatePriceFromKwh = useCalculatePriceFromKwh()
	const calculatePriceFromWater = useCalculatePriceFromWater()

	return (kwh: number | false, water: number | false): number | false => {
		const priceKwh = calculatePriceFromKwh(kwh)
		const priceWater = calculatePriceFromWater(water)

		if (priceKwh === false && priceWater === false) return false
		if (priceWater === false) return priceKwh
		if (priceKwh === false) return priceWater
		return Math.round((priceKwh + priceWater) * 100) / 100
	}
}

export const useCalculateCO2FromKwh = (
	energiebron: 'elektriciteit' | 'gas' = 'elektriciteit'
) => {
	const {
		user: {profile},
		general
	} = useApp()

	return (kwh: number | false): number | false => {
		if (kwh === false) return false
		if (energiebron === 'gas')
			return Math.round(general.profile.emissie_aardgas * kwh * 100) / 100
		const grijs = general.profile.emissie_grijze_stroom * kwh
		const groen = grijs * general.profile.factor_groene_stroom
		return Math.round((profile.groene_stroom ? groen : grijs) * 100) / 100
	}
}

export const ProfileSidebar: React.FC<{
	open: boolean
	onClose: () => void
	attributes: ProfileAttribute[]
}> = ({open, onClose, attributes}) => {
	const app = useApp()
	const t = useTranslation()

	return (
		<CalculatorSidebar open={open} onClose={onClose}>
			<form
				className={styles.sidebar()}
				onSubmit={e => {
					onClose()
					e.preventDefault()
				}}
			>
				<div className={styles.sidebar.header()}>
					<button
						onClick={onClose}
						title={t.profile.close}
						aria-label={t.profile.close}
						className={styles.sidebar.header.close()}
					>
						<Icon icon="times_light" />
					</button>
					<h2 className={styles.sidebar.header.title()}>{t.profile.title}</h2>
					<LinkStyled
						to="/nl/account?tab=profile&subtab=personal"
						target="_blank"
						iconafter="chevron_right"
						mod={['medium', 'small']}
					>
						{t.profile.link}
					</LinkStyled>
				</div>
				<div className={styles.sidebar.fields()}>
					{profileAttributes
						.filter(attribute => attributes.find(t => t === attribute))
						.map((attribute, i) => (
							<ProfileField
								field={attribute}
								onChange={app.updateProfile}
								state={app.user.profile}
								key={i}
							/>
						))}
				</div>
				<div className={styles.sidebar.footer()}>
					<LinkStyled
						iconafter="undo_solid"
						mod={['medium', 'small']}
						onClick={() => {
							const update: any = attributes.reduce(
								(acc, key) => ({...acc, [key]: undefined}),
								{}
							)
							// Reset aardgasprijs when verwarmingsprijs is reset and default type is 'aardgas'
							if (
								attributes.find(attr => attr === 'verwarmingsprijs') &&
								app.general.profile.default_verwarmingstype === 'aardgas'
							) {
								update.aardgasprijs = undefined
							}
							// Reset verwarmingsprijs when aardgasprijs is reset and type is 'aardgas'
							if (
								attributes.find(attr => attr === 'aardgasprijs') &&
								app.user.profile.verwarmingstype === 'aardgas'
							) {
								update.verwarmingsprijs = undefined
							}
							app.updateProfile(update)
						}}
					>
						{t.profile.reset}
					</LinkStyled>
				</div>
			</form>
		</CalculatorSidebar>
	)
}

export const ProfileField: React.FC<{
	label?: string
	field: ProfileAttribute
	state: ProfileState
	onChange: (updatedState: Partial<ProfileState>) => void
}> = ({field, label, state, onChange}) => {
	const app = useApp()
	const config = app.general.profile
	const t = useTranslation()

	switch (field) {
		case 'gezinsleden':
			return (
				<Config
					label={label || config.gezinsleden_question}
					info={config.gezinsleden_info}
					icon="male_light"
				>
					<ConfigInteger
						value={state.gezinsleden}
						unitFor={value =>
							value === 1
								? t.profile.gezinsleden_eenheden.meervoud
								: t.profile.gezinsleden_eenheden.enkelvoud
						}
						min={1}
						max={10}
						onChange={value => onChange({gezinsleden: value})}
					/>
				</Config>
			)

		case 'woningtype':
			return (
				<Config
					label={label || config.woningtype_question}
					info={config.woningtype_info}
					icon="building_light"
				>
					<ConfigDropdown
						value={state.woningtype}
						onChange={value => onChange({woningtype: value as WoningType})}
						options={woningTypes.map(type => ({
							key: type,
							label: t.profile.woningtype_labels[type]
						}))}
					/>
				</Config>
			)

		case 'isolatiegraad':
			return (
				<Config
					label={label || config.isolatiegraad_question}
					info={config.isolatiegraad_info}
					icon="insulation"
				>
					<ConfigDropdown
						value={state.isolatiegraad}
						onChange={value =>
							onChange({isolatiegraad: value as IsolatiegraadType})
						}
						options={isolatiegraadTypes.map(type => ({
							key: type,
							label: t.profile.isolatiegraad_labels[type]
						}))}
					/>
				</Config>
			)

		case 'kamertemperatuur':
			return (
				<Config
					label={label || config.kamertemperatuur_question}
					info={config.kamertemperatuur_info}
					icon="thermometer_half_light"
				>
					<ConfigRange
						value={state.kamertemperatuur}
						min={15}
						max={25}
						step={1}
						onChange={value => onChange({kamertemperatuur: value})}
						unit={{pre: '', post: '°C'}}
					/>
				</Config>
			)

		case 'groene_stroom':
			return (
				<Config
					label={label || config.groene_stroom_question}
					info={config.groene_stroom_info}
					icon="green_energy"
				>
					<ConfigCheckbox
						value={state.groene_stroom}
						onChange={value => onChange({groene_stroom: value})}
						label={t.profile.groene_stroom_checkbox}
					/>
				</Config>
			)

		case 'stroomprijs':
			return (
				<Config
					label={label || config.stroomprijs_question}
					info={config.stroomprijs_info}
					icon="euro_sign_light"
					htmlFor={field}
				>
					<ConfigRange
						value={state.stroomprijs}
						min={0.01}
						max={config.default_stroomprijs * 2}
						step={config.default_stroomprijs / 20}
						onChange={value => onChange({stroomprijs: value})}
						unit={{pre: '€', post: '/ kWh'}}
						id={field}
					/>
				</Config>
			)

		case 'verwarmingsinstallatie':
			return (
				<Config
					label={label || config.verwarmingsinstallatie_question}
					info={config.verwarmingsinstallatie_info}
					icon="heating_system"
				>
					<ConfigDropdown
						value={state.verwarmingsinstallatie}
						onChange={value => {
							const verwarmingsinstallatie = value as VerwarmingsInstallatieType
							onChange({
								verwarmingsinstallatie,
								verwarmingstype: getSelectableVerwarmingsTypes(
									verwarmingsinstallatie
								)[0] as VerwarmingsType,
								verwarmingsprijs:
									value === 'aardgas'
										? state.aardgasprijs
										: config[`default_prijs_${value as VerwarmingsType}`]
							})
						}}
						options={verwarmingsInstallaties.map(type => ({
							key: type,
							label: t.profile.verwarmingsinstallatie_labels[type]
						}))}
					/>
				</Config>
			)

		case 'verwarmingstype':
			if (
				state.verwarmingsinstallatie === 'lucht_warmtepomp' ||
				state.verwarmingsinstallatie === 'bodemwarmte_warmtepomp' ||
				state.verwarmingsinstallatie === 'grondwater_warmtepomp'
			)
				return null

			return (
				<Config
					label={label || config.verwarmingstype_question}
					info={config.verwarmingstype_info}
					icon="heat_light"
				>
					<ConfigDropdown
						value={state.verwarmingstype}
						onChange={value =>
							onChange({
								verwarmingstype: value as VerwarmingsType,
								verwarmingsprijs:
									value === 'aardgas'
										? state.aardgasprijs
										: config[`default_prijs_${value as VerwarmingsType}`]
							})
						}
						options={getSelectableVerwarmingsTypes(
							state.verwarmingsinstallatie
						).map(type => ({
							key: type,
							label: t.profile.verwarmingstype_labels[type]
						}))}
					/>
				</Config>
			)

		case 'verwarmingsprijs':
			if (
				state.verwarmingsinstallatie === 'lucht_warmtepomp' ||
				state.verwarmingsinstallatie === 'bodemwarmte_warmtepomp' ||
				state.verwarmingsinstallatie === 'grondwater_warmtepomp'
			)
				return null

			const verwarmingstype = state.verwarmingstype
			const defaultValue =
				verwarmingstype === 'aardgas'
					? Math.round((config.default_prijs_aardgas / 11) * 100) / 100
					: config[`default_prijs_${verwarmingstype}`]
			const unit = t.profile.verwarmingsprijs_eenheden[verwarmingstype]
			return (
				<Config
					label={label || config.verwarmingsprijs_question}
					info={config.verwarmingsprijs_info}
					icon="euro_sign_light"
				>
					<ConfigRange
						value={state.verwarmingsprijs}
						min={0.01}
						max={defaultValue * 2}
						step={defaultValue / 20}
						onChange={value =>
							state.verwarmingstype === 'aardgas'
								? onChange({verwarmingsprijs: value, aardgasprijs: value})
								: onChange({verwarmingsprijs: value})
						}
						unit={{pre: '€', post: unit}}
					/>
				</Config>
			)

		case 'waterprijs':
			return (
				<Config
					label={label || config.waterprijs_question}
					info={config.waterprijs_info}
					icon="euro_sign_light"
				>
					<ConfigRange
						value={state.waterprijs}
						min={0.01}
						max={config.default_waterprijs * 2}
						step={config.default_waterprijs / 20}
						onChange={value => onChange({waterprijs: value})}
						unit={{pre: '€', post: '/ m³'}}
					/>
				</Config>
			)

		case 'aardgasprijs':
			return (
				<Config
					label={label || config.aardgasprijs_question}
					info={config.aardgasprijs_info}
					icon="euro_sign_light"
				>
					<ConfigRange
						value={state.aardgasprijs}
						min={0.01}
						max={(config.default_prijs_aardgas / 11) * 2}
						step={config.default_prijs_aardgas / 11 / 20}
						onChange={value =>
							state.verwarmingstype === 'aardgas'
								? onChange({aardgasprijs: value, verwarmingsprijs: value})
								: onChange({aardgasprijs: value})
						}
						unit={{pre: '€', post: '/ kWh'}}
					/>
				</Config>
			)

		case 'jaarverbruik':
			return (
				<Config
					label={label || config.jaarverbruik_question}
					info={config.jaarverbruik_info}
					icon="electric_meter"
				>
					<ConfigRange
						value={state.jaarverbruik}
						min={1}
						max={25000}
						step={1}
						onChange={value => onChange({jaarverbruik: value})}
						unit={{pre: '', post: 'kWh'}}
					/>
				</Config>
			)

		case 'stookolieprijs':
			return (
				<Config
					label={label || config.stookolieprijs_question}
					info={config.verwarmingsprijs_info}
					icon="euro_sign_light"
				>
					<ConfigRange
						value={state.stookolieprijs}
						min={0.01}
						max={config.default_prijs_stookolie * 2}
						step={config.default_prijs_stookolie / 20}
						onChange={value => onChange({stookolieprijs: value})}
						unit={{
							pre: '€',
							post: t.calculator.units.liter(state.stookolieprijs)
						}}
					/>
				</Config>
			)

		case 'propaan_butaanprijs':
			return (
				<Config
					label={label || config.propaan_butaanprijs_question}
					info={config.verwarmingsprijs_info}
					icon="euro_sign_light"
				>
					<ConfigRange
						value={state.propaan_butaanprijs}
						min={0.01}
						max={config.default_prijs_propaan_butaan * 2}
						step={config.default_prijs_propaan_butaan / 20}
						onChange={value => onChange({propaan_butaanprijs: value})}
						unit={{
							pre: '€',
							post: t.calculator.units.liter(state.propaan_butaanprijs)
						}}
					/>
				</Config>
			)

		case 'wagen_groene_stroom':
			return (
				<Config
					label={label || config.wagen_groene_stroom_question}
					info={config.wagen_groene_stroom_info}
					icon="green_energy"
				>
					<ConfigDropdown
						value={state.wagen_groene_stroom}
						onChange={value =>
							onChange({
								wagen_groene_stroom: value as WagenGroeneStroomType
							})
						}
						options={wagenGroeneStroomTypes.map(type => ({
							key: type,
							label: t.profile.wagen_groene_stroom_labels[type]
						}))}
					/>
				</Config>
			)

		case 'wagen_stroomprijs':
			return (
				<Config
					label={label || config.wagen_stroomprijs_question}
					info={config.wagen_stroomprijs_info}
					icon="euro_sign_light"
				>
					<ConfigRange
						value={state.wagen_stroomprijs}
						min={0.01}
						max={config.default_wagen_stroomprijs * 2}
						step={config.default_wagen_stroomprijs / 20}
						onChange={value => onChange({wagen_stroomprijs: value})}
						unit={{pre: '€', post: '/ kWh'}}
					/>
				</Config>
			)

		case 'wagen_brandstof':
			return (
				<Config
					label={label || config.wagen_brandstof_question}
					info={config.wagen_brandstof_info}
					icon="gas_pump_regular"
				>
					<ConfigDropdown
						value={state.wagen_brandstof}
						onChange={value =>
							onChange({
								wagen_brandstof: value as WagenBrandstofType,
								wagen_brandstofprijs:
									config[`default_prijs_${value as WagenBrandstofType}`]
							})
						}
						options={wagenBrandstofTypes.map(type => ({
							key: type,
							label: t.profile.wagen_brandstof_labels[type]
						}))}
					/>
				</Config>
			)

		case 'wagen_brandstofprijs':
			const brandstof = state.wagen_brandstof
			const brandstofDefaultValue =
				config[`default_brandstofprijs_${brandstof}`]
			const brandstofUnit = t.profile.wagen_brandstofprijs_eenheden[brandstof]
			return (
				<Config
					label={label || config.wagen_brandstofprijs_question}
					info={config.wagen_brandstofprijs_info}
					icon="euro_sign_light"
				>
					<ConfigRange
						value={state.wagen_brandstofprijs}
						min={0.01}
						max={brandstofDefaultValue * 2}
						step={brandstofDefaultValue / 20}
						onChange={value => onChange({wagen_brandstofprijs: value})}
						unit={{pre: '€', post: brandstofUnit}}
					/>
				</Config>
			)
	}
	return null
}

const getSelectableVerwarmingsTypes = (
	verwarmingsinstallatie: VerwarmingsInstallatieType
): VerwarmingsType[] => {
	switch (verwarmingsinstallatie) {
		case 'pelletketel':
			return ['pellets']
		case 'hoogrendementsketel':
			return ['aardgas', 'stookolie', 'propaan_butaan']
		case 'condensatieketel':
			return ['aardgas', 'stookolie', 'propaan_butaan']
		case 'hybride':
			return ['aardgas', 'stookolie', 'propaan_butaan']
		case 'lucht_warmtepomp':
			return []
		case 'grondwater_warmtepomp':
			return []
		case 'bodemwarmte_warmtepomp':
			return []
		case 'oude_verwarmingsinstallatie':
			return ['aardgas', 'elektriciteit', 'propaan_butaan', 'stookolie']
		default:
			assertNever(verwarmingsinstallatie)
	}
}
