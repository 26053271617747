export const toolbarSmall =
	'undo redo | bold italic subscript superscript | link unlink'
export const toolbar =
	'undo redo | formatselect | bold italic subscript superscript | removeformat | bullist numlist | link unlink'
export const toolbarFull =
	'undo redo | styleselect | bold italic subscript superscript | removeformat | bullist numlist blockquote | link unlink | pastetext code'

export const formats =
	'Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Paragraph=p;'

export const styleFormatsHeaders = [
	{
		title: 'Headers',
		items: [
			{title: 'Heading 2', block: 'h2'},
			{title: 'Heading 3', block: 'h3'},
			{title: 'Heading 4', block: 'h4'},
			{title: 'Heading 5', block: 'h5'},
			{title: 'Paragraph', block: 'p'}
		]
	}
]

export const styleFormatsAlign = [
	{
		title: 'Align',
		items: [
			{title: 'Left', format: 'alignleft'},
			{title: 'Center', format: 'aligncenter'},
			{title: 'Right', format: 'alignright'},
			{title: 'Justify', format: 'alignjustify'}
		]
	}
]

export const styleFormatsButtons = [
	{
		title: 'Buttons',
		items: [
			{
				selector: 'a',
				classes: 'btn btn-orange',
				title: 'Button orange'
			},
			{
				selector: 'a',
				classes: 'btn btn-purple',
				title: 'Button purple'
			}
		]
	}
]

export const styleFormatsImage = [
	{
		title: 'Image',
		items: [
			{
				selector: 'img',
				styles: {'border-radius': '100%'},
				title: 'Border-radius: 100%'
			},
			{
				selector: 'img',
				styles: {'margin-top': '-80px'},
				title: 'Margin-top: -80px'
			}
		]
	}
]

export const styleFormatsList = [
	{
		title: "List",
		items: [
			{
				selector: "li",
				styles: {"margin-top": "10px"},
				title: "List item - margin-top: 10px"
			}
		]
	}
]

export const styleFormatsTable = [
	{
		title: 'Table',
		items: [
			{
				selector: 'table',
				styles: {'margin-top': '60px'},
				title: 'Table - margin-top: 60px'
			},
			{
				selector: 'th,td',
				styles: {padding: '0px'},
				title: 'Cell padding: 0px'
			},
			{
				selector: 'th,td',
				styles: {padding: '5px'},
				title: 'Cell padding: 5px'
			},
			{
				selector: 'th,td',
				styles: {padding: '10px'},
				title: 'Cell padding: 10px'
			},
			{
				selector: 'th,td',
				styles: {padding: '20px'},
				title: 'Cell padding: 20px (default)'
			},
			{
				selector: 'th,td',
				styles: {padding: '25px'},
				title: 'Cell padding: 25px'
			}
		]
	}
]

export const customStyleFormats = [
	styleFormatsHeaders,
	styleFormatsAlign,
	styleFormatsButtons,
	styleFormatsList
]
