import {Icon} from './Icon'
import {Link} from 'layout/Link'
import React from 'react'
import css from './Social.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type SocialType = {
	social?: Array<SocialLinkType>
	className?: string
}

export type SocialLinkType = {
	type:
		| 'facebook'
		| 'instagram'
		| 'linkedin'
		| 'rss_feed'
		| 'twitter'
		| 'youtube'
	url: string
}

export const Social: React.FC<SocialType> = ({social, className}) => {
	if (!social || social.length === 0) return null

	return (
		<div className={styles.social.with(className)()}>
			<div className={styles.social.row()}>
				{social.map((item, i) => {
					if (!item?.type || !item?.url) return null
					return (
						<div key={i} className={styles.social.row.item()}>
							<SocialLink key={item.type} {...item} />
						</div>
					)
				})}
			</div>
		</div>
	)
}

export const SocialLink: React.FC<SocialLinkType> = ({type, url}) => {
	return (
		<Link to={url} title={type} className={styles.link.mod(type)()}>
			<Icon icon={type} />
		</Link>
	)
}
