import {Icon, IconKey} from './Icon'
import React, {HTMLProps} from 'react'

import {Link} from 'layout/Link'
import {Variant} from 'util/styler'
import css from './Button.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useRouter} from 'next/router'

const styles = fromModule(css)

type ButtonType = (
	| Omit<HTMLProps<HTMLAnchorElement>, 'as'>
	| ({as: 'button'} & HTMLProps<HTMLButtonElement>)
	| ({as: 'a'} & HTMLProps<HTMLAnchorElement>)
) & {
	mod?: Variant<
		| 'purple'
		| 'grey'
		| 'white'
		| 'purple_outline'
		| 'white_outline'
		| 'small'
		| 'large'
		| 'line'
	>
	to?: string
	iconbefore?: IconKey
	iconafter?: IconKey
	shallow?: boolean
	isDirty?: boolean
}

export const Button: React.FC<ButtonType> = ({
	mod,
	iconbefore,
	iconafter,
	children,
	disabled,
	shallow,
	isDirty,
	...props
}) => {
	let ButtonTag: any = Link
	if ('as' in props && props.as === 'button') ButtonTag = 'button'
	if ('as' in props && props.as === 'a') ButtonTag = 'a'
	const router = useRouter()

	let onClick = props.onClick
	if (!onClick && shallow) {
		onClick = e => {
			e.preventDefault()
			router.push(props.to, undefined, {shallow: true})
		}
	}

	return (
		<ButtonTag
			{...props}
			disabled={disabled}
			className={styles.button.mergeProps(props).mod(mod)()}
			onClick={onClick}
		>
			{iconbefore && <ButtonIcon icon={iconbefore} dirty={isDirty} />}
			<ButtonText>{children}</ButtonText>
			{iconafter && <ButtonIcon icon={iconafter} />}
		</ButtonTag>
	)
}

const ButtonText: React.FC = props => (
	<span {...props} className={styles.text()} />
)

const ButtonIcon: React.FC<{icon: IconKey; dirty?: boolean}> = ({
	icon,
	dirty
}) => (
	<span className={styles.icon.mod(icon).is({dirty})()}>
		<Icon icon={icon} />
	</span>
)
