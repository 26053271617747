import {
	AutogidsAandrijvingBron,
	AutogidsAandrijvingCategory,
	AutogidsAandrijvingSubcategory,
	AutogidsVorm
} from 'autogids/autogids.state'
import {GlasType, RaamkaderMateriaal} from 'calculators/vensters/vensters.state'
import {
	IsolatiegraadType,
	VerwarmingsType,
	WagenBrandstofType,
	WagenGroeneStroomType,
	WoningType
} from 'calculators/profile.channel'
import {MuurIsolatiePositie, MuurType} from 'calculators/muren/muren.state'

import {CalculationUnit} from 'layout/calculators/Calculation'
import {DroogkastCapaciteit} from 'calculators/droogkast/droogkast.state'
import {EnergieLabel} from 'calculators/labels'
import {HellendDakType} from 'calculators/dak/dak.state'
import {OvenDimensie} from 'calculators/oven/oven.channel'
import {OvenEnergiebron} from 'calculators/oven/oven.state'
import {ProfileAttribute} from 'calculators/Profile'
import {Rijstijl} from 'calculators/wagen/wagen_rijden/wagenrijden.state'
import {VaatwasserCapaciteit} from 'calculators/vaatwasser/vaatwasser.state'
import {VerwarmingsInstallatieType} from 'calculators/verwarming/verwarming.state'
import {WasmachineCapaciteit} from 'calculators/wasmachine/wasmachine.state'
import {nl} from './nl'

export const fr: typeof nl = {
	projectname: 'EnergyWatchers',
	federalheader: {
		text:
			'Autres informations et services officiels' +
			String.fromCharCode(160) +
			':',
		link: {
			title: 'www.belgium.be',
			url: 'https://www.belgium.be/fr'
		}
	},
	menu: {
		account: {
			url: '/fr/compte',
			title: 'Mon EnergyWatchers'
		},
		search: {
			url: '/fr/chercher',
			title: 'Chercher'
		}
	},
	account: {
		header_toast:
			"N'oubliez pas de vous <u>connecter ou de créer un compte</u> pour enregistrer vos préférences.",
		register: {
			title: 'Créer un compte',
			name: 'Nom',
			email: 'Adresse e-mail',
			password: 'Mot de passe',
			repeat_password: 'Confirmez le mot de passe',
			privacy_agreement:
				"J'accepte les <a href='/fr/pagina' target='_blank'>modalités de protection de la vie privée</a>.",
			newsletter:
				"J'accepte de recevoir des communications provenant de EnergyWatchers.",
			login: 'Connectez-vous avec votre compte',
			submit: 'Créer mon compte',
			mail_sent: {
				message:
					"Votre compte a été créé, mais doit encore être activé. Nous vous avons envoyé un e-mail avec un lien d'activation.<br />Cliquez sur ce lien pour activer votre compte.",
				no_mail:
					"Vous n'avez reçu aucun e-mail" + String.fromCharCode(160) + '?',
				button: 'Envoyer de nouveau'
			}
		},
		login: {
			title: 'Se connecter',
			email: 'Adresse e-mail',
			password: 'Mot de passe',
			forgot_password: 'Mot de passe oublié',
			register: 'Créer un compte',
			submit: 'Se connecter'
		},
		forgot_password: {
			title: 'Réinitialiser le mot de passe',
			email: 'Adresse e-mail',
			cancel: 'Annuler',
			submit: 'Envoyer',
			mail_sent: {
				message:
					"Votre compte a été créé, mais doit encore être activé. Nous vous avons envoyé un e-mail avec un lien d'activation.<br />Cliquez sur ce lien pour restaurer votre compte.",
				no_mail:
					"Vous n'avez reçu aucun e-mail" + String.fromCharCode(160) + '?',
				button: 'Envoyer de nouveau'
			}
		},
		logout: 'Se déconnecter',
		update: {
			title: 'Coordonnées',
			name: 'Nom',
			email: 'Adresse e-mail',
			password: 'Nouveau mot de passe',
			repeat_password: 'Confirmez votre nouveau mot de passe',
			submit: 'Sauvegarder',
			delete: 'Supprimer mon compte',
			confirm:
				'Êtes-vous certain·e de vouloir supprimer votre compte' +
				String.fromCharCode(160) +
				'?',
			subscribe: "Je m'inscris"
		},
		toasts: {
			//General toasts
			pending: 'Traitement en cours...',
			error: "Un problème est survenu pendant l'enregistrement.",
			success: 'Vos données ont été mises à jour.',
			warning: "Le stockage web local n'est pas disponible",

			//Specific toasts
			incorrect_login: "La combinaison email/mot de passe n'est pas correcte.",
			pending_login: 'Connexion en cours...',
			account_deleted: 'Votre compte a été supprimé.',
			account_created: 'Votre compte a été créé.',
			activate: 'Votre compte a été créé. Découvrez votre espace personnel.',
			passwords_dont_match: 'Les mots de passe ne coïncident pas.',
			logged_in: 'Connexion réussie',
			logged_out: "Vous n'êtes plus connecté·e.",
			password_reset:
				'Un e-mail a été envoyé pour réinitialiser votre mot de passe.',
			success_mail: "L'e-mail a été envoyé.",
			success_subscribe: 'Enregistrement réussi'
		}
	},
	tips: {
		share: 'Partager',
		favorite: {
			save: 'Sauvegarder',
			delete: 'Sauvegardé'
		},
		status: {
			favorite: 'Favori',
			todo: 'À faire ',
			done: 'Fait '
		},
		purchase: 'Achat',
		behaviour: 'Comportement',
		environment: 'Environnement',
		premiums: 'Primes',
		info: 'Info',
		impact: 'Impact',
		investment: 'Investissement',
		loadmore: 'Afficher plus de conseils',
		items: 'résultat(s)',
		sort: 'Trier' + String.fromCharCode(160) + ':',
		sort_randomly: 'Tri aléatoire',
		sort_impact: 'Trier par impact',
		sort_investment: 'Trier par investissement',
		find_tips: 'Je cherche un conseil sur...',
		themes: 'Thèmes',
		filters: {
			reset: 'Supprimer tous les filtres',
			title: 'Filtres',
			status: {
				title: 'État ',
				favorite: 'Favori',
				done: 'Fait',
				open: 'À faire'
			},
			type: {
				title: 'Type de conseil',
				purchase: 'Achat',
				behaviour: 'Comportement',
				environment: 'Environnement',
				premiums: 'Primes',
				info: 'Info'
			},
			impact: {
				title: 'Impact'
			},
			investment: {
				title: 'Investissement',
				'0': '0 - 10 €',
				'1': '10 - 100 €',
				'2': '100 - 1 000 €',
				'3': '1 000 - 10 000 €',
				'4': '> 10 000 €'
			},
			subcategory: {
				title: 'Domaine'
			}
		},
		empty: "Il n'y a aucun résultat qui correspond aux critères spécifiés.",
		toasts: {
			error: "Un problème est survenu pendant l'enregistrement",
			favorite_add: 'Conseil ajouté aux favoris.',
			favorite_remove: 'Conseil retiré des favoris.',
			tip_add: 'Conseil appliqué.',
			tip_remove: 'Conseil à appliquer.'
		}
	},
	inspiration: {
		linklabel: "Lire l'article",
		loadmore: "Afficher plus de sources d'inspiration",
		items: 'résultat(s)',
		sort: 'Trier' + String.fromCharCode(160) + ':'
	},
	related: 'Vous pourriez être intéressé·e par...',
	share: {
		label: 'Partager',
		click: 'Cliquer pour partager'
	},
	social: {
		facebook: 'Facebook',
		linkedin: 'LinkedIn',
		reddit: 'Reddit',
		telegram: 'Telegram',
		twitter: 'Twitter',
		whatsapp: 'WhatsApp'
	},
	contact: {
		email: {
			label: 'Adresse e-mail',
			placeholder: 'Votre adresse e-mail'
		},
		subject: {
			label: 'Objet',
			placeholder: 'Objet de votre message'
		},
		message: {
			label: 'Message',
			placeholder: 'Votre message'
		},
		submit: 'Envoyer',
		toasts: {
			error: "Un problème est survenu pendant l'envoi.",
			pending: 'Envoi en cours...',
			success: 'Votre message a été envoyé.'
		}
	},
	search: {
		placeholder: 'Rechercher...',
		title: 'Résultats',
		empty: 'Aucun résultat trouvé'
	},
	profile: {
		title: 'Profil personnel',
		link: 'Voir mon profil complet',
		reset: 'Retour aux valeurs moyennes',
		close: 'Fermer',
		groene_stroom_checkbox: 'énergie verte',
		categories: {
			gezin: 'Foyer',
			woning: 'Habitation',
			elektriciteit: 'Électricité',
			verwarming: 'Chauffage',
			water: 'Eau',
			aardgas: 'Gaz naturel (pour cuisiner)',
			wagen: 'Voiture'
		},
		attributes: {
			gezinsleden: 'Nombre de membres dans le foyer',
			woningtype: "Type d'habitation",
			isolatiegraad: "Degré d'isolation",
			groene_stroom: 'Électricité verte',
			stroomprijs: 'Prix unitaire',
			jaarverbruik: 'Consommation annuelle',
			kamertemperatuur: 'Température ambiante moyenne',
			verwarmingsinstallatie: 'Installation actuelle',
			verwarmingstype: "Source d'énergie",
			verwarmingsprijs: 'Prix unitaire de la source énergétique',
			waterprijs: 'Prix unitaire',
			aardgasprijs: 'Prix unitaire',
			wagen_brandstof: 'Carburant',
			wagen_brandstofprijs: 'Prix du carburant',
			wagen_groene_stroom: "Rechargement par de l'électricité verte",
			wagen_stroomprijs: "Prix unitaire de l'électricité"
		} as Record<ProfileAttribute, string>,
		gezinsleden_eenheden: {
			enkelvoud: 'membre',
			meervoud: 'membres'
		},
		woningtype_labels: {
			studio_of_klein_appartement: 'Studio ou petit appartement',
			gemiddeld_appartement_of_klein_rijhuis:
				'Appartement moyen ou petite maison de ville',
			rijhuis_of_groot_appartement: 'Maison de ville ou grand appartement',
			groot_rijhuis_of_alleenstaande_woning:
				'Grande maison de ville ou 4 façades',
			villa: 'Villa',
			kasteel: 'Château'
		} as Record<WoningType, string>,
		isolatiegraad_labels: {
			niet: "Pas d'isolation",
			slecht:
				"Mauvaise isolation (couche d'isolation peu épaisse, pas de vitrage à haut rendement, etc.)",
			redelijk:
				'Isolation convenable (nouvelle construction, isolation de la toiture de 8 cm, vitrage à haut rendement sur la plupart des fenêtres, etc.)',
			goed: 'Bonne isolation (maison à basse consommation énergétique avec une valeur k inférieure à 30)',
			heel_goed:
				'Très bonne isolation (maison passive avec une valeur K inférieure à 20)'
		} as Record<IsolatiegraadType, string>,
		verwarmingsinstallatie_labels: {
			oude_verwarmingsinstallatie: 'Système de chauffage ancien',
			pelletketel: 'Chaudière à pellets',
			hoogrendementsketel: 'Chaudière à haut rendement',
			condensatieketel: 'Chaudière à condensation',
			hybride:
				'Hybride' +
				String.fromCharCode(160) +
				': pompe à chaleur + chaudière à haut rendement',
			lucht_warmtepomp: 'Pompe à chaleur air/air',
			grondwater_warmtepomp: 'Pompe à chaleur air/eau',
			bodemwarmte_warmtepomp: 'Pompe à chaleur air/sol'
		} as Record<VerwarmingsInstallatieType, string>,
		verwarmingstype_labels: {
			stookolie: 'Mazout',
			aardgas: 'Gaz naturel',
			propaan_butaan: 'Propane/butane',
			pellets: 'Pellets',
			elektriciteit: 'Électricité'
		} as Record<VerwarmingsType, string>,
		verwarmingsprijs_eenheden: {
			stookolie: '/ litre(s)',
			aardgas: '/ kWh',
			propaan_butaan: '/ litre(s)',
			pellets: '/ kg',
			elektriciteit: '/ kWh'
		} as Record<VerwarmingsType, string>,
		wagen_groene_stroom_labels: {
			altijd: 'Toujours',
			soms: 'Parfois',
			nooit: 'Jamais'
		} as Record<WagenGroeneStroomType, string>,
		wagen_brandstof_labels: {
			diesel: 'Diesel',
			benzine: 'Essence',
			lpg: 'GPL',
			cng: 'GNC',
			waterstof: 'Hydrogène'
		} as Record<WagenBrandstofType, string>,
		wagen_brandstofprijs_eenheden: {
			diesel: '/ litre(s)',
			benzine: '/ litre(s)',
			lpg: '/ litre(s)',
			cng: '/ kg',
			waterstof: '/ kg'
		} as Record<WagenBrandstofType, string>,
		product_profiles: {
			title: 'Caractéristiques du produit',
			link: 'Afficher les caractéristiques du produit',
			save: 'Enregistrer les caractéristiques du produit',
			categories: {
				home: 'Habitation',
				electro: 'Électro',
				movements: 'Déplacements'
			}
		}
	},
	calculator: {
		submit: 'OK',
		enter: "ou appuyez sur la touche d'entrée",
		previous: 'Précédent',
		next: 'Suivant',
		restart: 'Recommencer',
		materiaal: 'Matériau' + String.fromCharCode(160) + ':',
		dikte: 'Épaisseur' + String.fromCharCode(160) + ':',
		dit_komt_overeen_met: 'Cela correspond à',
		verbruik_personaliseren: 'Personnaliser mon profil',
		verbruik_aanpassen: 'Modifier mon profil',
		besparing_personaliseren: 'Personnaliser les économies réalisées',
		besparing_aanpassen: 'Modifier mon profil',
		toestel_kiezen: 'Choisir un appareil',
		toestel_aanpassen: "Modifier l'appareil",
		wagen_kiezen: 'Choisir un véhicule',
		wagen_aanpassen: 'Modifier le véhicule',
		close_comparison: 'Fermer le comparatif',
		after_march_2021: 'après mars 2021',
		before_march_2021: 'avant 2021',
		dak: {
			lengte_vloer:
				'Longueur du plancher (qui correspond à la longueur totale de la toiture)' +
				String.fromCharCode(160) +
				':',
			breedte_vloer:
				'Largeur du plancher (qui correspond à la largeur totale de la toiture)' +
				String.fromCharCode(160) +
				':',
			hoogte_tot_nok: "Hauteur jusqu'au faîte" + String.fromCharCode(160) + ':',
			lengte_nok: 'Longueur du faîte' + String.fromCharCode(160) + ':',
			oppervlakte_dakvensters:
				'Surface de toutes les fenêtres de toit combinées' +
				String.fromCharCode(160) +
				':',
			nok_righting: 'Comment est le faîte' + String.fromCharCode(160) + '?',
			nok_righting_options: {
				lengte: 'Parallèle à la façade la plus longue (toit long)',
				breedte: 'Parallèle à la façade la plus courte (toit large)'
			},
			oppervlakte_dak:
				'Surface de votre toit en pente (sans fenêtres de toit) est de' +
				String.fromCharCode(160) +
				':',
			dak_long: 'Long',
			dak_large: 'Large'
		},
		vensters: {
			oppervlakte_enkel:
				'Surface du simple vitrage' + String.fromCharCode(160) + ':',
			oppervlakte_dubbel:
				'Surface du double vitrage' + String.fromCharCode(160) + ':'
		},
		glas_labels: {
			hoogrendementsglas: ['Vitrage à haut rendement', 'haut rendement'],
			driedubbelglas: ['Triple vitrage', 'triple vitrage']
		} as Record<GlasType, [string, string]>,
		raamkader_labels: {
			PUR: 'PUR',
			PVC: 'PVC',
			aluminium: 'aluminium',
			hout: 'bois',
			staal: 'acier'
		} as Record<RaamkaderMateriaal, string>,
		oven_energiebronnen: {
			elektriciteit: 'Électricité',
			gas: 'Gaz'
		} as Record<OvenEnergiebron, string>,
		oven_attributes: {
			hoogte: 'Hauteur' + String.fromCharCode(160) + ':',
			breedte: 'Largeur' + String.fromCharCode(160) + ':'
		} as Record<OvenDimensie, string>,
		oven_eenheden: {
			uur_per_bakbeurt: 'heure(s) par cuisson',
			keer_per_jaar: 'fois par an'
		},
		kookplaten_eenheden: {
			uur_per_week: 'heure(s) par semaine',
			pitten: 'foyer(s)'
		},
		wasmachine_gebruiken: {
			ja: 'Oui, presque toujours',
			soms: 'Oui, de temps en temps',
			nee: 'Non, jamais'
		},
		wasmachine_capaciteiten: {
			5: "jusqu'à 5 kg",
			6: '6 kg',
			7: '7 kg',
			8: '8 kg',
			9: '9 kg',
			10: '10 kg',
			11: 'plus de 10 kg'
		} as Record<WasmachineCapaciteit, string>,
		vaatwasser_capaciteiten: {
			6: '6',
			7: '7',
			8: '8',
			9: '9',
			10: '10',
			11: '11',
			12: '12',
			13: '13',
			14: '14',
			15: '15',
			16: '16'
		} as Record<VaatwasserCapaciteit, string>,
		droogkast_capaciteiten: {
			7: '7 kg',
			8: '8 kg',
			9: '9 kg',
			10: '10 kg ou plus'
		} as Record<DroogkastCapaciteit, string>,
		wagen_rijstijlen: {
			zuinig: 'Très économique',
			sportief: 'Sportif',
			ik_let_er_niet_op: "Je n'y prête pas attention"
		} as Record<Rijstijl, string>,
		units: {
			kwh: _ => 'kWh',
			co2: _ => 'kg de CO₂',
			euro: a => (Math.abs(a) > 1 ? 'euros' : 'euro'),
			ballon: a => (Math.abs(a) > 1 ? 'ballons' : 'ballon'),
			tankwagen: a => (Math.abs(a) > 1 ? 'citernes' : 'citerne'),
			m2: _ => 'm²',
			dikte: _ => 'cm',
			liter: a => (Math.abs(a) > 1 ? 'litres' : 'litre')
		} as Record<CalculationUnit | 'liter', (amount: number) => string>,
		energy_label: {
			A: 'A',
			B: 'B',
			C: 'C',
			D: 'D',
			E: 'E',
			F: 'F',
			G: 'G',
			oud_A_ppp: 'A+++',
			oud_A_pp: 'A++',
			oud_A_p: 'A+',
			oud_A: 'A',
			oud_B: 'B',
			oud_C: 'C',
			oud_D: 'D',
			oud_onbekend:
				'Un vieil appareil, mais je ne connais pas son étiquette énergétique'
		} as Record<EnergieLabel, string>,
		muurtypes: {
			massief: 'Un mur plein',
			spouwmuur: 'Un mur creux'
		} as Record<MuurType, string>,
		isolatie_positie: {
			binnen: "À l'intérieur",
			spouw: 'Dans la cavité',
			buiten: "À l'extérieur"
		} as Record<MuurIsolatiePositie, string>,
		yes: 'Oui',
		no: 'Non',
		hellend_dak_types: {
			zadeldak: 'toit à 2 pans simple',
			geveldak: 'toit à pignons',
			wolfsdak: 'toit à demi-croupe',
			lessenaarsdak: 'toit à 1 seul pan',
			mansardedak: 'toit mansardé',
			schilddak: 'toit à croupe',
			puntdak: 'toit en pavillon'
		} as Record<HellendDakType, string>
	},
	error404: {
		title: 'Page non trouvée',
		text: 'La page que vous recherchez a peut-être été supprimée, son nom a été modifié ou est temporairement indisponible.',
		btn: "Allez à la page d'accueil"
	},
	error500: {
		title: 'Erreur',
		text: "Une erreur s'est produite lors de la génération de la page. Veuillez réessayer plus tard.",
		btn: "Aller à la page d'accueil"
	},
	autogids: {
		vorm: {
			stadswagen: 'Petite (urbaine)',
			berline: 'Berline (familiale)',
			break: 'Break',
			monovolume: 'Monospace',
			suv: 'SUV et 4x4',
			minibus: 'Minibus',
			coupe: 'Coupée',
			cabrio: 'Cabriolet',
			roadster: 'Roadster',
			sportief: 'Ultra-sportive',
			limousine: 'Limousine'
		} as Record<AutogidsVorm, string>,
		aandrijving: {
			cat: {
				elektrisch: '100 % électrique',
				hybride: 'Hybride',
				verbranding: 'Moteur à combustion'
			} as Record<AutogidsAandrijvingCategory, string>,
			subcat: {
				elektrisch_batterij: 'batterie (BEV)',
				elektrisch_brandstof: 'pile à combustible (FCEV)',
				hybride_plug: 'hybride rechargeable (PHEV)',
				hybride_zelf: 'hybride auto-rechargeable (HEV)',
				verbranding_vloeibaar: 'combustible liquide (ICE)',
				verbranding_gas: 'combustible gazeux (ICE)'
			} as Record<AutogidsAandrijvingSubcategory, string>,
			bron: {
				elektrisch_batterij_e: 'électricité',
				elektrisch_brandstof_h: 'hydrogène',
				hybride_plug_be: 'essence + électricité',
				hybride_plug_de: 'diesel + électricité',
				hybride_zelf_be: 'essence + électricité',
				hybride_zelf_de: 'diesel + électricité',
				verbranding_vloeibaar_b: 'essence',
				verbranding_vloeibaar_d: 'diesel',
				verbranding_gas_lpg: 'GPL',
				verbranding_gas_aardgas: 'gaz naturel (GNC)'
			} as Record<AutogidsAandrijvingBron, string>
		},
		uitstoot: {
			category: 'Catégorie',
			max: 'Maximum',
			unit: 'g/km'
		},
		terms: {
			merk_model: 'Marque et modèle',
			search_placeholder: "Tapez le nom d'une marque et/ou d'un modèle",
			vorm: 'Type',
			aandrijving: 'Motorisation',
			uitstoot: 'Émissions de CO₂',
			resultaat: ' résultat',
			resultaten: ' résultats',
			resultaat_voor: " résultat pour '<term>'",
			resultaten_voor: "résultats pour '<term>'",
			compare: 'Comparer',
			reset: 'Recommencer',
			yes: 'oui',
			no: 'non'
		},
		tables: {
			title: {
				elektrisch: 'Voitures 100 % électriques',
				hybride: 'Voitures hybrides',
				verbranding:
					'Véhicules propulsés par des moteurs à combustion interne uniquement'
			},
			text: "Sélectionnez dans les tableaux ci-dessous les voitures que vous souhaitez comparer. Vous pouvez comparer jusqu'à 3 modèles en même temps.",
			terms: {
				merk: 'marque',
				model: 'modèle',
				capaciteit: 'capacité',
				vermogen: 'puissance',
				vermogen_unit: 'CV fisc.',
				verbruik: 'consommation',
				aandrijving: 'motorisation',
				uitstoot: 'émissions de CO₂.',
				autonomie: 'autonomie',
				plug: 'rechargeable' + String.fromCharCode(160) + '?',
				euronorm: 'norme Euro',
				euronorm_split: 'norme Euro',
				brandstof: 'carburant',
				elektriciteit: 'électricité',
				waterstof: 'hydrogène',
				categorie: 'catégorie',
				nox: 'émissions de NOₓ'
			}
		},
		compare: {
			title: 'Comparatif des modèles sélectionnés'
		}
	},
	emails: {
		general: {
			slogan:
				'Le site fédéral belge<br>pour réduire vos émissions de CO<sub>2</sub>',
			link: 'Vers le site web',
			greenhosting:
				"Nous utilisons de l'énergie verte pour l'hébergement de ce site.",
			climate_link: 'https://climat.be',
			climate2050_link: 'https://climat.be/2050-fr',
			twitter_link: 'https://twitter.com/climat_be',
			linkedin_link: 'https://www.linkedin.com/company/belgium-climate-change',
			privacy_statement: 'Mentions légales',
			privacy_link: 'mentions-legales'
		},
		signup: {
			subject: 'Activez votre compte EnergyWatchers',
			title: 'Activez votre compte',
			message:
				"Merci d'avoir créé votre compte sur EnergyWatchers.<br>Cliquez sur le lien ci-dessous pour l'activer" +
				String.fromCharCode(160) +
				':',
			button: 'Activer mon compte'
		},
		forgot_password: {
			subject: 'Récupérez votre mot de passe EnergyWatchers',
			og_title: 'Récupérez votre mot de passe EnergyWatchers',
			title: 'Récupérez votre mot de passe',
			message: 'Cliquez sur ce lien pour récupérer votre mot de passe.',
			button: 'Récupérer mon mot de passe'
		}
	}
}
