export function fetchJson<T = void>(
	input: RequestInfo,
	init: RequestInit,
	successStatus = 200
): Promise<T> {
	return new Promise(async (resolve, reject) => {
		const response = await fetch(input, init)

		if (response.status !== successStatus) {
			reject(
				`Invalid response status received, expected ${successStatus}, got ${response.status}`
			)
		}

		const data = await response.json()
		resolve(data)
	})
}
