import * as Sentry from '@sentry/nextjs'

import {
	ABCProducts,
	ProductStates,
	productCategories
} from 'account/ProductProfiles'
import {ProfileState, fillProfileWithDefaults} from 'calculators/Profile'
import {createContext, useContext, useEffect, useState} from 'react'

import {CMSWebsiteChannel} from 'cms-types'
import {GeneralData} from 'server/general'
import {LangKey} from 'locale/locale'
import {PersonalTip} from 'account/PersonalProfile'
import {fetchJson} from 'util/fetchJson'
import {isLocalStorageAvailable} from 'util/localStorage'
import {toastify} from 'util/toastify'
import {useTranslation} from './locale/LocaleContext'

export type AppEnvType = 'production' | 'staging' | 'development'

export type AppContextData = {
	general: GeneralData
	node: CMSWebsiteChannel
	lang: LangKey
	app_env: AppEnvType
	loaded?: boolean
}

export type UserAccount = {
	email: string
	name: string
	profile: string
	products: string
	favorites: string
	tips: string
	verified: Date
}

export type UserState = {
	isUpdated: boolean
	partialProfile: Partial<ProfileState>
	profile: ProfileState
	products_A: ProductStates
	products_B: ProductStates
	products_C: ProductStates
	favorites: number[]
	tips: PersonalTip[]
}

export type AppContextType = {
	account?: UserAccount
	user: UserState
	login: (account: UserAccount) => void
	updateAccount: (account: UserAccount) => void
	logout: () => void
	updateProfile: (update: Partial<ProfileState>) => Promise<void>
	updateProduct: (
		product: 'A' | 'B' | 'C',
		update: ProductStates
	) => Promise<void>
	updateFavorites: (update: number[]) => Promise<void>
	updateTips: (update: PersonalTip[]) => Promise<void>
} & AppContextData

const AppContext = createContext<AppContextType>(null)

let scheduled: ReturnType<typeof setTimeout> = null

export const AppContextProvider: React.FC<AppContextData> = ({
	children,
	...props
}) => {
	const t = useTranslation()
	const [account, setAccount] = useState<UserAccount>(null)
	const [loaded, setLoaded] = useState(null)
	const [partialProfile, setPartialProfile] = useState<Partial<ProfileState>>(
		{}
	)
	const [favorites, setFavorites] = useState<number[]>([])
	const [tips, setTips] = useState<PersonalTip[]>([])
	const [products, setProducts] = useState<ABCProducts>({
		A: {},
		B: {},
		C: {}
	})
	const localStorageAvailable = isLocalStorageAvailable()

	const scheduleToast = (toastTrigger: () => void) => {
		if (scheduled) clearTimeout(scheduled)
		scheduled = setTimeout(() => {
			toastTrigger()
			scheduled = null
		}, 650)
	}

	const updateProfile = async (update: Partial<ProfileState>) => {
		const newPartialprofile = {...partialProfile, ...update, personalized: true}
		setPartialProfile(newPartialprofile)

		const toast = await toastify()
		if (account) {
			const promise = fetchJson<UserAccount>('/api/auth/user', {
				method: 'PUT',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					username: account.email,
					profile: newPartialprofile
				})
			})
			scheduleToast(() => toast.promise(promise, t.account.toasts))

			promise.catch(() => {
				setPartialProfile(partialProfile)
			})
		} else if (localStorageAvailable) {
			localStorage.profile = JSON.stringify(newPartialprofile)
			scheduleToast(() => toast.success(t.account.toasts.success))
		} else {
			scheduleToast(() => toast.warning(t.account.toasts.warning))
		}
	}

	const updateProduct = async (
		category: 'A' | 'B' | 'C',
		update: ProductStates
	) => {
		const newProducts = {...products[category], ...update}
		setProducts({...products, [category]: newProducts})

		const toast = await toastify()
		if (account) {
			const promise = fetchJson<UserAccount>('/api/auth/user', {
				method: 'PUT',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					username: account.email,
					products: {...products, [category]: newProducts}
				})
			})
			scheduleToast(() => toast.promise(promise, t.account.toasts))
			promise.catch(() => {
				setProducts(products)
			})
		} else if (localStorageAvailable) {
			localStorage[`products_${category}`] = JSON.stringify(newProducts)
			scheduleToast(() => toast.success(t.account.toasts.success))
		} else {
			scheduleToast(() => toast.warning(t.account.toasts.warning))
		}
	}

	const updateFavorites = async (update: Array<number>) => {
		const successMsg =
			favorites.length < update.length
				? t.tips.toasts.favorite_add
				: t.tips.toasts.favorite_remove
		setFavorites(update)

		const toast = await toastify()
		if (account) {
			const promise = fetchJson<UserAccount>('/api/auth/user', {
				method: 'PUT',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					username: account.email,
					favorites: update
				})
			})
			toast.promise(promise, {
				error: t.tips.toasts.error,
				success: successMsg
			})

			promise.catch(() => {
				setFavorites(favorites)
			})
		} else if (localStorageAvailable) {
			localStorage.favorites = JSON.stringify(update)
			scheduleToast(() => toast.success(successMsg))
		} else {
			scheduleToast(() => toast.warning(t.account.toasts.warning))
		}
	}

	const updateTips = async (update: PersonalTip[]) => {
		const successMsg =
			tips.length < update.length
				? t.tips.toasts.tip_add
				: t.tips.toasts.tip_remove
		setTips(update)

		const toast = await toastify()
		if (account) {
			const promise = fetchJson<UserAccount>('/api/auth/user', {
				method: 'PUT',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					username: account.email,
					tips: update
				})
			})
			toast.promise(promise, {
				error: t.tips.toasts.error,
				success: successMsg
			})

			promise.catch(() => {
				setTips(tips)
			})
		} else if (localStorageAvailable) {
			localStorage.tips = JSON.stringify(update)
			scheduleToast(() => toast.success(successMsg))
		} else {
			scheduleToast(() => toast.warning(t.account.toasts.warning))
		}
	}

	const login = (account: UserAccount) => {
		setPartialProfile(JSON.parse(account.profile))
		setProducts(JSON.parse(account.products))
		setAccount(account)
		setFavorites(JSON.parse(account.favorites))
		setTips(JSON.parse(account.tips))
		setLoaded(true)
		if (localStorageAvailable) {
			localStorage.removeItem('profile')
			productCategories.forEach(category => {
				localStorage.removeItem(`products_${category}`)
			})
			localStorage.removeItem('favorites')
			localStorage.removeItem('tips')
		}
	}
	const updateAccount = setAccount
	const logout = () => {
		setAccount(null)
		setPartialProfile({})
		setProducts({A: {}, B: {}, C: {}})
		setFavorites([])
		setTips([])
		return fetch('/api/auth/logout')
	}

	const fetchUser = async (): Promise<UserAccount | null> => {
		const result = await fetch('/api/auth/user')
		if (result.ok) return result.json()
		return null
	}

	useEffect(() => {
		fetchUser().then((account: UserAccount | null) => {
			if (account) {
				// Ingelogde gebruiker
				Sentry.setUser({email: account.email})
				login(account)
			} else {
				// Geen ingelogde gebruiker - gebruik local storage indien beschikbaar
				if (localStorageAvailable) {
					const profileRaw = localStorage.profile
					if (profileRaw) {
						setPartialProfile(JSON.parse(profileRaw))
					}
					const favoritesRaw = localStorage.favorites
					if (favoritesRaw) {
						setFavorites(JSON.parse(favoritesRaw))
					}
					const tipsRaw = localStorage.tips
					if (tipsRaw) {
						setTips(JSON.parse(tipsRaw))
					}
					let productsRawObject = {}
					productCategories.forEach(category => {
						const productsRaw = localStorage[`products_${category}`]
						productsRawObject = {
							...productsRawObject,
							[category]: productsRaw ? JSON.parse(productsRaw) : {}
						}
					})
					setProducts(productsRawObject)
				}
				setLoaded(true)
			}
		})
	}, [])

	const config = {
		profile: props.general.profile,
		verwarming: props.general.verwarming
	}
	const profile = fillProfileWithDefaults(partialProfile, config)

	const user: UserState = {
		isUpdated:
			Object.keys(partialProfile).length > 0 ||
			Object.keys(products.A).length > 0 ||
			Object.keys(products.B).length > 0 ||
			Object.keys(products.C).length > 0 ||
			favorites.length > 0 ||
			tips.length > 0,
		partialProfile,
		profile,
		favorites,
		tips,
		products_A: products.A,
		products_B: products.B,
		products_C: products.C
	}

	return (
		<AppContext.Provider
			value={{
				...props,
				loaded,
				account,
				user,
				login,
				updateAccount,
				logout,
				updateProfile,
				updateProduct,
				updateFavorites,
				updateTips
			}}
		>
			{children}
		</AppContext.Provider>
	)
}

export const useApp = () => {
	const result = useContext(AppContext)

	if (!result) {
		throw new Error(
			'Trying to use the useApp hook, but no AppContext is defined.'
		)
	}

	return {
		...result,
		updateUser: (user: UserState) => {}
	}
}
