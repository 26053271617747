import 'react-toastify/dist/ReactToastify.css'
import 'styles/blocks.scss'
import 'styles/globals.scss'
import 'styles/odometer.scss'

import {AppContextProvider, AppEnvType} from 'AppContext'
import {CMSChannelMeta, CMSWebsiteChannel} from 'cms-types'
import {LangKey, defaultLanguage, languages} from 'locale/locale'
import {LocaleContextProvider, useTranslation} from 'locale/LocaleContext'

import {AdminTools} from 'layout/admin/AdminTools'
import type {AppProps} from 'next/app'
import {GeneralData} from 'server/general'
import Head from 'next/head'
import {Layout} from 'layout/Layout'
import dynamic from 'next/dynamic'
import smoothscroll from 'smoothscroll-polyfill'
import {useEffect} from 'react'

const ToastContainer = dynamic(
	() => import('react-toastify').then(mod => mod.ToastContainer),
	{ssr: false}
)

function _app({Component, pageProps, router, ...rest}: AppProps) {
	const t = useTranslation()
	const routeLang: LangKey = router.asPath.substring(1, 3) as LangKey
	const detectedLang: LangKey = languages.includes(routeLang)
		? routeLang
		: defaultLanguage

	const general: GeneralData =
		pageProps.general || pageProps.generals[detectedLang]
	const node: CMSWebsiteChannel = pageProps.node
	const lang: LangKey = (node?.language as LangKey) || detectedLang
	const app_env: AppEnvType = pageProps.app_env
	const meta: CMSChannelMeta = node?.metadata

	if (typeof window !== 'undefined') smoothscroll.polyfill()

	useEffect(() => {
		if (window.location.hostname === 'energy-watchers.vercel.app')
			router.push('https://energywatchers.be')
	}, [])

	useEffect(() => {
		document.documentElement.lang = lang
	}, [lang])

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta name="language" content={lang} />
				{app_env === 'production' ? (
					<meta name="robots" content="index, follow" />
				) : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<link rel="shortcut icon" href="/favicon.png" />
				{(meta || node) && (
					<>
						<title>
							{meta?.title && meta?.title.trim() !== node.title.trim()
								? meta.title
								: node.title + ' - ' + t.projectname}
						</title>
						<meta property="og:type" content="website" />
						<meta
							property="og:url"
							content={process.env.NEXT_PUBLIC_APP_URL + node.url}
						/>
						<meta
							property="og:title"
							content={meta?.og?.title || meta?.title || node.title}
						/>
					</>
				)}
				{meta?.description && (
					<>
						<meta name="description" content={meta.description} />
						<meta
							property="og:description"
							content={meta.og?.description || meta.description}
						/>
					</>
				)}
				{meta?.image && (
					<>
						<meta
							property="og:image"
							content={process.env.NEXT_PUBLIC_APP_URL + meta.image.src}
						/>
						<meta property="og:image:width" content={'' + meta.image.width} />
						<meta property="og:image:height" content={'' + meta.image.height} />
						<meta
							name="twitter:card"
							content={
								meta.image?.height >= meta.image?.width
									? 'summary'
									: 'summary_large_image'
							}
						/>
					</>
				)}
				<script
					dangerouslySetInnerHTML={{
						__html: `var _mtm = window._mtm = window._mtm || [];
							_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
							var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
							g.async=true; g.src='https://analytics.klimaat.be/js/container_LeLTp7H0.js'; s.parentNode.insertBefore(g,s);`
					}}
				/>
			</Head>
			<LocaleContextProvider lang={lang}>
				<AppContextProvider
					general={general}
					node={node}
					lang={lang}
					app_env={app_env}
				>
					{node && <AdminTools node={node} />}
					<Layout>
						{node && <Component {...node} />}
						{!node && <Component />}
					</Layout>
					<ToastContainer position="bottom-right" autoClose={3000} />
				</AppContextProvider>
			</LocaleContextProvider>
		</>
	)
}

export default _app
