import {Icon, IconKey} from 'layout/Icon'
import React, {HTMLProps} from 'react'

import {Link} from './Link'
import {UrlObject} from 'url'
import {Variant} from 'util/styler'
import css from './LinkStyled.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const LinkStyled: React.FC<
	HTMLProps<HTMLAnchorElement> & {
		to?: string | UrlObject
		iconbefore?: IconKey
		iconafter?: IconKey
		mod?: Variant<'inherit' | 'grey' | 'white' | 'regular' | 'medium' | 'small'>
	}
> = ({to, iconbefore, iconafter, mod, children, ...props}) => {
	let LinkTag: any = Link
	if ('as' in props && props.as === 'button') LinkTag = 'button'
	if ('as' in props && props.as === 'span') LinkTag = 'span'

	return (
		<div className={styles.linkstyled.with(props.className)()}>
			<LinkTag
				{...(props as any)}
				to={to}
				className={styles.linkstyled.link.mod(mod)()}
			>
				{iconbefore && <LinkStyledIcon icon={iconbefore} />}
				<LinkStyledText>{children}</LinkStyledText>
				{iconafter && <LinkStyledIcon icon={iconafter} />}
			</LinkTag>
		</div>
	)
}

const LinkStyledText: React.FC = ({children}) => (
	<span className={styles.text()}>{children}</span>
)

const LinkStyledIcon: React.FC<{icon: IconKey}> = ({icon}) => (
	<span className={styles.icon.mod(icon)()}>
		<Icon icon={icon} />
	</span>
)
